import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Layout from './components/Layout'
import { useAuthInit } from './helpers/authInit'
import { getTheme, ThemeContext } from './hooks/useTheme'

export function App() {
  const [themeName, setThemeName] = useState('defaultTheme')
  const { isAuthorized, logOut } = useAuthInit()
  const navigate = useNavigate()

  useEffect(() => {
    if (!isAuthorized) {
      navigate('/sign-in')
    }
  }, [isAuthorized, navigate])

  const theme = getTheme(themeName)

  return (
    <>
      <ThemeContext.Provider value={theme}>
        <Layout />
      </ThemeContext.Provider>
    </>
  )
}

export default App
