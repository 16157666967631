//
import { useState } from 'react'
import { useContext } from 'react'
import { Link } from 'react-router-dom'

import { getTheme, ThemeContext } from '../../hooks/useTheme'
import { Row, SelectableRow } from '../../types/Row'

interface IProps {
  headers?: string[]
  rows: Row[] | SelectableRow[]
  onSelectedRowClick?: (rows: SelectableRow[]) => void
  collection?: string
  queryParameter?: string
}

export function Table(props: IProps) {
  const theme = useContext(ThemeContext)

  const { collection, rows, onSelectedRowClick } = props
  let isNavigatable = false
  if (collection != null) {
    isNavigatable = true
  }

  const rowsAsSelectable = rows as SelectableRow[]
  const [selectableRows, setSelectableRows] = useState<SelectableRow[]>(rowsAsSelectable)

  function isSelectableRow(row: SelectableRow | Row) {
    if ('selected' in row) {
      return true
    }
  }

  if (!rows) return <div className='text-base text-neutral-400 mb-6'>no data...</div>

  function select(rowId: string) {
    const newRows = selectableRows.map((row) => {
      if (row.id === rowId) {
        row.selected = !row.selected
      }
      return row
    })

    setSelectableRows(newRows)

    if (onSelectedRowClick) {
      onSelectedRowClick(newRows)
    }
  }

  return (
    <div className='flex flex-col max-w-2xl'>
      <div className='overflow-x-auto'>
        <div className='align-middle inline-block min-w-full'>
          <div className='shadow overflow-hidden border-b border-gray-200 rounded-lg'>
            <div className='table-auto min-w-full divide-gray-200'>
              <div className={'bg-blue-900 grid '}>
                <div className='px-4 py-1 text-left text-sm text-white '>Name</div>
              </div>
              <div className='bg-slate-50 divide-y divide-gray-200'>
                {rows.map((row) => (
                  <div key={row.id}>
                    <div className='px-4 py-2 whitespace-nowrap text-sm text-gray-900'>
                      {isSelectableRow(row) && props.onSelectedRowClick && (
                        <input
                          id={row.id}
                          aria-describedby={row.name}
                          name={row.name}
                          type='checkbox'
                          className='focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded mr-2'
                          onClick={() => select(row.id)}
                        />
                      )}
                      {isNavigatable && <Link to={`/${collection}/${row.id}`}>{row.name}</Link>}
                      {!isNavigatable && row.name}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
