import { useQuery } from 'react-query'

import AssetsAPI from '../services/assets.service'

export const useAssetsForArea = (areaId: string, page: number, pageSize: number) => {
  const { data: assetsData } = useQuery(
    ['assets', areaId, { page, pageSize }],
    async () => await AssetsAPI.getAssetsForArea(areaId!, page, pageSize),
    {
      enabled: !!areaId,
    },
  )

  return assetsData
}

export const useAsset = (assetId?: string) => {
  const { data: assetData } = useQuery(['assets', assetId], async () => await AssetsAPI.getAsset(assetId!), {
    enabled: !!assetId,
  })

  return assetData
}

export const useAssetsForSite = (siteId: string, page: number, pageSize: number) => {
  const { data: assetsData } = useQuery(
    ['assets', siteId, { page, pageSize }],
    async () => await AssetsAPI.getAssetsForSite(siteId, page, pageSize),
    {
      enabled: !!siteId,
    },
  )

  return assetsData
}

export const usePreviousMaintenanceChecks = (assetId: string) => {
  const previousMaintenanceChecksResults = useQuery(
    ['assets', 'maintenancechecks', assetId],
    () => AssetsAPI.getAssetMaintenanceChecks(assetId!),
    {
      enabled: !!assetId,
    },
  )

  return previousMaintenanceChecksResults
}

export const useAssetMaintenanceCheck = (assetId: string) => {
  const assetMaintenanceCheckResult = useQuery(['assets', 'latestMaintenanceCheck', assetId], () =>
    AssetsAPI.getLatestMaintenanceCheck(assetId!),
  )
  return assetMaintenanceCheckResult
}
