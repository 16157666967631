import Sortable from 'sortablejs'
import { object } from 'yup'

import { Asset } from '../types/Asset'
import { MaintenanceCheck } from '../types/MaintenanceCheck'
import HttpService from './http.service'

interface GetMaintenceChecksResponse {
  checks: MaintenanceCheck[]
}

interface SortItemsCommand {
  items: SortableItem[]
}

interface SortableItem {
  id: string
  sequence: number
}

class AssetsAPI extends HttpService {
  getAsset = async (assetId: string): Promise<Asset> => {
    return await this.get(`assets/${assetId}`)
  }

  getAssetsForSite = async (siteId: string, page: number, pageSize: number) => {
    return await this.get(`assets?siteId=${siteId}&page=${page}&pageSize=${pageSize}`)
  }

  getAssetsForArea = async (areaId: string, page: number, pageSize: number) => {
    return await this.get(`assets?areaId=${areaId}&page=${page}&pageSize=${pageSize}`)
  }

  getAssetComponentsAndHoses = async (assetId: string) => {
    return await this.get(`assets/${assetId}/Components/`)
  }

  getLatestMaintenanceCheck = async (assetId: string) => {
    return await this.get(`assets/${assetId}/latestmaintenancecheck/`)
  }

  postAsset = async (siteId: string, name: string, areaId?: string) => {
    return await this.post('assets', { siteId, areaId, name })
  }

  putAsset = async (assetId: string, name: string, notes?: string, description?: string) => {
    return await this.put('assets', { id: assetId, name, notes, description })
  }

  updateCheckField = async (
    organisationId: string,
    assetId: string,
    templateId: string,
    fieldName: string,
    fieldValue: string,
    componentId?: string,
  ) => {
    const request = {
      organisationId,
      assetId,
      templateId,
      fieldName,
      fieldValue,
    }

    if (componentId != null) {
      Object.assign(request, componentId)
    }

    return await this.post('assets/commands/updatecheck/', request)
  }

  sortAssets = async (assets: Asset[]) => {
    const items = assets.map((asset, index) => ({ id: asset.id, sequence: index }))
    return await this.post('assets/commands/sort', { items: items })
  }

  postMaintenanceCheck = async (assetId: string) => {
    return await this.post(`assets/${assetId}/maintenancechecks/`, { assetId })
  }

  publishAssetMaintenanceCheck = async (assetId: string) => {
    return await this.post('assets/commands/publish', { assetId })
  }

  getAssetMaintenanceChecks = async (assetId: string): Promise<GetMaintenceChecksResponse> => {
    return await this.get(`assets/${assetId}/maintenancechecks/`)
  }

  postNote = async (assetId: string, description: string) => {
    return await this.post(`assets/${assetId}/notes`, { description: description })
  }

  deleteNote = async (assetId: string, noteId: string) => {
    return await this.delete(`assets/${assetId}/notes/${noteId}`)
  }
}

export default new AssetsAPI({
  apiURL: process.env.REACT_APP_BACKENDURL,
})
