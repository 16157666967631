// https://flatcolors.net/palettes.php
// https://color.adobe.com/trends

const defaultTheme = {
  A: '#A88700',
  B: '#FFD426',
  C: '#F5C60C',
  D: '#0008A8',
  E: '#8E93F5',
  F: '#5493F5',
  Warning: '#A80600',
}

const mudTheme = {
  A: '#353535',
  B: '#3c6e71',
  C: '#ffffff',
  D: '#d9d9d9',
  E: '#284b63',
  F: '#3f81ac',
  Warning: '#A80600',
}

export interface Theme {
  A: string
  B: string
  C: string
  D: string
  E: string
  F: string
  Warning: string
}

function getTheme(): Theme {
  const theme = localStorage.getItem('Theme')

  if (!theme) {
    return defaultTheme
  }

  return mudTheme
}

export { getTheme }
