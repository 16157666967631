import { useQuery } from 'react-query'

import HosesAPI from '../services/hoses.service'

export const useHoses = (assetId: string, page: number, pageSize: number) => {
  const { data: hosesData } = useQuery(
    ['hoses', assetId, page, pageSize],
    async () => await HosesAPI.getHoses(assetId!, page, pageSize),
    {
      enabled: !!assetId,
    },
  )

  return hosesData
}

export const useHose = (hoseId: string) => {
  const { data: hoseData } = useQuery(['hoses', hoseId], async () => await HosesAPI.getHose(hoseId!), {
    enabled: !!hoseId,
  })

  return hoseData
}
