import { Tab } from '../types/Tab'
import { ActionBar } from './ActionBar'

interface IProps {
  name: string
  tabs: Tab[]
}

export function Header(props: IProps) {
  const { name, tabs } = props

  return (
    // <>
    <div className='bg-white shadow rounded-lg mb-4 py-2 flex'>
      {/* <div className='pl-4 pt-2 pb-2 pr-4 '> */}
      <h1 className='text-gray-900 px-4 py-2'>{name}</h1>
      {/* </div> */}
      <div className='border-r-2'></div>
      <div className='pl-2 flex gap-2'>
        <ActionBar tabs={tabs} />
      </div>
    </div>

    // </>
  )
}
// {/* <div className='bg-white shadow rounded-lg mb-2 sm:mb-4 sm:hidden'>
//         {/* <div className='pl-4 pt-2 pb-2 pr-4 '> */}
//         <h1 className='text-gray-900 px-4 py-2'>{name}</h1>
//         {/* </div> */}
//         <div className='pl-2 flex gap-2 '>
//           <ActionBar tabs={tabs} />
//         </div>
//       </div> */}
