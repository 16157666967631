import { InformationCircleIcon } from '@heroicons/react/solid'
import { ErrorMessage, Field } from 'formik'
import { useState } from 'react'

interface IProps {
  id: string
  description: string
  type?: string
  required?: boolean
  readonly?: boolean
  value?: string // overriding value from formik
}

export function TextBox(props: IProps) {
  const { description, readonly, value, type, required, id } = props
  const [showInformation, setShowInformation] = useState<boolean>(false)

  function inferMeasurement(measurementName: string) {
    if (measurementName.includes('temperature')) {
      return '°c'
    }

    if (measurementName.includes('pressure')) {
      return '(bar)'
    }

    return ''
  }

  let numberOfGridForValue = 'col-span-2'
  if (type === 'number') {
    numberOfGridForValue = 'col-span-1'
  }

  const numberOfGridForLabel = numberOfGridForValue === 'col-span-2' ? 'col-span-1' : 'col-span-2'

  const measurement = inferMeasurement(description)

  let isReadOnly = ''
  if (props.readonly) {
    isReadOnly = 'bg-slate-200'
  }
  return (
    <>
      <div className='sm:items-start'>
        <div className={`${numberOfGridForLabel}`}>
          <label htmlFor={props.id} className='block text-gray-700 pb-2'>
            {description} {measurement}
          </label>
        </div>

        {value && (
          <Field
            id={id}
            name={id}
            type={type === 'number' ? 'number' : 'text'}
            required={props.required}
            readOnly={readonly}
            value={value}
            defaultValue={value}
            className={`${numberOfGridForValue} ${isReadOnly}  flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 rounded-md rounded-r-md sm:text-sm border-gray-300`}
          />
        )}

        {!value && (
          <Field
            id={id}
            name={id}
            type={type === 'number' ? 'number' : 'text'}
            required={required}
            readOnly={readonly}
            className={`${numberOfGridForValue} ${isReadOnly} flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 rounded-md rounded-r-md sm:text-sm border-gray-300`}
          />
        )}
      </div>
    </>
  )
}
