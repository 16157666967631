import { useQuery } from 'react-query'

export type UserInfo = {
  sub: string
  name: string
  email: string
  picture?: string
}

export const useUserInfo = () => {
  const token = localStorage.getItem('AUTH_TOKEN_KEY')?.split('.')[1]

  let decodedTokenString: any = null
  if (token != null) {
    const decodedToken = atob(token!)
    decodedTokenString = JSON.parse(decodedToken) as UserInfo
  }

  return useQuery<UserInfo, Error>('user:info', () => ({
    sub: decodedTokenString ? decodedTokenString.sub : '',
    name: decodedTokenString ? decodedTokenString.email.replace('.', ' ') : '',
    email: decodedTokenString ? decodedTokenString.email : '',
    picture:
      'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  }))
}

function urlBase64Decode(str: string) {
  let output = str.replace(/-/g, '+').replace(/_/g, '/')
  switch (output.length % 4) {
    case 0:
      break
    case 2:
      output += '=='
      break
    case 3:
      output += '='
      break
    default:
      // tslint:disable-next-line:no-string-throw
      throw 'Illegal base64url string!'
  }
  return decodeURIComponent((<any>window).escape(window.atob(output)))
}
