import { InformationCircleIcon } from '@heroicons/react/outline'
import { ErrorMessage, Field } from 'formik'
import { useState } from 'react'

import { FormikCalendar } from '../../../components/formik/FormikCalendar'
import { InformationalModal } from '../../../components/popups/InformationalModal'

interface ITwoFieldProps {
  label: string
  field1Id: string
  field2Id: string
  fieldStyle: string
}

interface Headings {
  field1Name: string
  field2Name: string
}

function TwoFields({ label, field1Id, field2Id, fieldStyle }: ITwoFieldProps) {
  return (
    <>
      <div className='grid grid-cols-6 gap-3 pb-1'>
        <div className='col-span-1'>{label}</div>
        <Field name={field1Id} type='text' className={fieldStyle} />
        <Field name={field2Id} type='text' className={fieldStyle} />
      </div>
      <div className='grid grid-cols-6 gap-3 pb-1'>
        <div></div>
        <ErrorMessage name={field1Id} />
        <ErrorMessage name={field2Id} />
      </div>
    </>
  )
}

function Heading({ field1Name, field2Name }: Headings) {
  return (
    <>
      <div className='grid grid-cols-6 gap-3'>
        <div></div>
        <div>{field1Name}</div>
        <div>{field2Name}</div>
        <div />
      </div>
    </>
  )
}

export function HoseForm() {
  const [showConditionInformation, setShowConditionInformation] = useState<boolean>(false)
  const [showHoseOrientation, setShowHoseOrientation] = useState<boolean>(false)

  const conditionInformation = `Hose condition score is on a 0-10 scaled. Based on internal visual inspection only. A Zero score
  indicates the hose has failed. A score of one(1) indicates that the hose is nearing imminent
  failure Scores of one(1) indicates that the hose is nearing imminent failure. Scores between 2-3
  are hoses nearing replacement at the the next oppourtunity to avoid failure risk. Scores between
  4-7 are hoses which are in 'used' or 'aged' condition, are expected to continue in service for
  some time or until their estimated service life is due. > 7 scores indicates that the hose is
  visually in good serviceable condition.`

  const hoseOrientation = `Hose orientation is determined with first listed fitting (fitting listed first or fitting#1)
  pointing upwards (vertical 12 o'clock) with the ferrule facing away from the user. The clock
  poition (12h clock) of the second fitting viewed fom this position (looking along the hose at the
  second fitting) determines the orientation of the hose build (looking in the same direction)`

  const style =
    'flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-md rounded-r-md sm:text-sm border-gray-300'

  return (
    <div className='block text-sm text-gray-700'>
      <Heading field1Name='Part number' field2Name='Description' />
      <TwoFields label='End 1' field1Id='end1PartNumber' field2Id='end1Description' fieldStyle={style} />
      <TwoFields label='Material' field1Id='materialPartNumber' field2Id='materialDescription' fieldStyle={style} />
      <TwoFields label='End 2' field1Id='end2PartNumber' field2Id='end2Description' fieldStyle={style} />
      <Heading field1Name='Overall' field2Name='Cut length' />
      <div className='grid grid-cols-6 gap-3 pb-1'>
        <div className='col-span-1'>Length (mm)</div>
        <Field name='lengthOverall' type='number' className={style} />
        <Field name='cutLength' type='number' className={style} />
      </div>

      <div className='grid grid-cols-6 gap-3 pb-1'>
        <div className='col-span-1 flex'>
          Orientation °(Degrees)
          <InformationCircleIcon
            className='ml-2 h-5 w-5 text-blue-800'
            aria-hidden='true'
            onClick={() => setShowHoseOrientation(true)}
          />
          {showHoseOrientation && (
            <InformationalModal
              label=''
              open={showHoseOrientation}
              text={hoseOrientation}
              setOpen={setShowHoseOrientation}
            />
          )}
        </div>
        <Field name='orientation' type='number' className={style} />
        <div></div>
      </div>
      <div className='grid grid-cols-6 gap-3 pb-1'>
        <div className='col-span-1 flex'>Lifetime (Years)</div>
        <Field name='lifetime' type='number' className={style} />
        <div></div>
      </div>
      <div className='grid grid-cols-6 gap-3 pb-1'>
        <div className='col-span-1 flex'>
          Condition score
          <InformationCircleIcon
            className='ml-2 h-5 w-5 text-blue-800'
            aria-hidden='true'
            onClick={() => setShowConditionInformation(true)}
          />
          {showConditionInformation && (
            <InformationalModal
              label=''
              open={showConditionInformation}
              text={conditionInformation}
              setOpen={setShowConditionInformation}
            />
          )}
        </div>
        <Field name='conditionScore' type='number' className={style} />
        <ErrorMessage name={'conditionScore'} />
        <div></div>
      </div>
      <div className='grid grid-cols-6 gap-3'>
        <div className='col-span-1'> Last changed date</div>
        <FormikCalendar key={'lastChangedDate'} fieldName='lastChangedDate' />
      </div>
    </div>
  )
}
