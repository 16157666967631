import { Field, Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useMutation, useQueryClient } from 'react-query'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { object, string } from 'yup'

import Files from '../../../components/basic/Files'
import { FileUpload } from '../../../components/basic/FileUpload'
import { Notes } from '../../../components/basic/Notes'
import { SaveAndCancel } from '../../../components/basic/SaveAndCancel'
import { CheckBox } from '../../../components/formik/CheckBox'
import { FormikCalendar } from '../../../components/formik/FormikCalendar'
import { FormikSelect } from '../../../components/formik/FormikSelect'
import { TextArea } from '../../../components/formik/TextArea'
import { TextBox } from '../../../components/formik/TextBox'
import { useAsset } from '../../../hooks/useAssets'
import { useRisk } from '../../../hooks/useRisks'
import { Header } from '../../../layouts/Header'
import RisksAPI from '../../../services/risks.service'
import SearchesAPI from '../../../services/searches.service'
import { IValue } from '../../../types/IValues'
import { CreateRiskRequest, Risk } from '../../../types/Risk'

const risksSchema = object({})

export function EditRisk() {
  const queryClient = useQueryClient()
  const { assetId, riskId } = useParams()
  const navigate = useNavigate()

  const assetData = useAsset(assetId!)
  const riskData = useRisk(riskId!)

  const [note, setNote] = useState<string>('')
  const [internalNote, setInternalNote] = useState<string>('')

  const { data: filesResult } = useQuery(['risks', 'files', riskId], async () => await RisksAPI.getFiles(riskId!), {
    enabled: !!riskId,
  })

  const values: IValue[] = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
  ]

  async function searchForAsset(inputValue: string): Promise<IValue[]> {
    const result = await SearchesAPI.searchAssets(organisationId, [], [], inputValue)
    return result.items.map((x) => ({ value: x.id, label: x.name }))
  }

  async function searchForComponents(inputValue: string): Promise<IValue[]> {
    const result = await SearchesAPI.searchComponents(organisationId, inputValue)
    return result.items.map((x) => ({ value: x.id, label: x.name }))
  }

  function onCancel() {
    navigate(`/assets/${assetId}/risks`)
  }

  let organisationId = ''
  if (assetData) {
    organisationId = assetData.organisationId
  }

  const updateRisk = useMutation((risk: Risk) => {
    return RisksAPI.putRisk(risk)
  })

  const handleSubmit = (values: Risk): void => {
    updateRisk.mutate(values, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['risks', riskId])
        await queryClient.invalidateQueries(['risks', assetId])
        navigate(`/assets/${assetId}/risks/`)
      },
    })
  }

  const addNoteMutation = useMutation((note: string) => {
    return RisksAPI.postNote(riskId!, note)
  })

  const addInternalNoteMutation = useMutation((note: string) => {
    return RisksAPI.postInternalNote(riskId!, note)
  })

  const removeNoteMutation = useMutation((noteId: string) => {
    return RisksAPI.deleteNote(riskId!, noteId)
  })

  const removeCustomerNoteMutation = useMutation((noteId: string) => {
    return RisksAPI.deleteInternalNote(riskId!, noteId)
  })

  function handleAddNote() {
    addNoteMutation.mutate(note, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['risks', riskId])
        setNote('')
      },
    })
  }

  function handleAddInternalNote() {
    addInternalNoteMutation.mutate(internalNote, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['risks', riskId])
        setInternalNote('')
      },
    })
  }

  function handleRemoveNote(noteId: string) {
    removeNoteMutation.mutate(noteId, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['risks', riskId])
      },
    })
  }

  function handleRemoveInternalNote(noteId: string) {
    removeCustomerNoteMutation.mutate(noteId, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['risks', riskId])
      },
    })
  }

  if (riskData != null) {
    riskData.observedOrPredictedFaultsAndRisks = riskData.observedOrPredictedFaultsAndRisks
      ? riskData.observedOrPredictedFaultsAndRisks
      : ''
    riskData.solution = riskData.solution ? riskData.solution : ''
    riskData.benefit = riskData.benefit ? riskData.benefit : ''
    riskData.resolution = riskData.resolution ? riskData.resolution : ''
    riskData.opportunity = riskData.opportunity ? riskData.opportunity : undefined
    riskData.budgetEstimate = riskData.budgetEstimate ? riskData.budgetEstimate : undefined
    riskData.timelineEstimate = riskData.timelineEstimate ? riskData.timelineEstimate : undefined
    riskData.budgetEstimate = riskData.budgetEstimate ? riskData.budgetEstimate : undefined
    riskData.capacityEstimate = riskData.capacityEstimate ? riskData.capacityEstimate : undefined
  }

  function RiskRatings() {
    return (
      <>
        <h1>Risk ratings</h1>
        <FormikSelect
          name='safetyOrEnvironmentalFactor'
          description='Safety or environmental factor'
          options={values}
        />
        <FormikSelect name='breakdownLikelihood' description='Breakdown likelihood' options={values} />
        <FormikSelect name='downtimeRisk' description='Downtime risk' options={values} />
        <FormikSelect name='criticality' description='Criticality' options={values} />
        <FormikSelect name='breakdownCostFactor' description='Breakdown cost factor' options={values} />
      </>
    )
  }

  return (
    <>
      <Header name='Risk' tabs={[]} />
      {riskData && (
        <div id='white-container' className='bg-white shadow rounded-lg p-4'>
          <Formik initialValues={riskData!} validationSchema={risksSchema} onSubmit={handleSubmit}>
            {({ dirty, isValid }) => {
              return (
                <Form>
                  <div className='grid grid-cols-1 gap-6 sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-2'>
                    <div id='left pane'>
                      <TextBox id='name' description='Risk name' />
                      <FormikSelect
                        multiSelect={true}
                        name='assets'
                        description='Related assets'
                        value={riskData!.assets}
                        lookUpFunction={searchForAsset}
                      />
                      <FormikSelect
                        multiSelect={true}
                        name='components'
                        description='Related components'
                        options={[]}
                        value={riskData!.components}
                        lookUpFunction={searchForComponents}
                      />
                      <TextArea
                        id='observedOrPredictedFaultsAndRisks'
                        description='Observed or predicted fault and risks'
                      />
                      <TextArea id='solution' description='Solution' />
                      <TextArea id='benefit' description='Benefit' />
                      <CheckBox id='opportunity' description='Opportunity' />
                      <TextBox id='budgetEstimate' description='Budget Estimate' type='number' />
                      <TextBox id='timelineEstimate' description='Timeline Estimate' type='number' />
                      <TextBox id='capacityEstimate' description='Capacity Estimate' type='number' />
                      <TextArea id='resolution' description='Resolution' />
                      <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start     sm:pt-1 sm:pb-1'>
                        <label htmlFor='lastChangedDate' className='block text-sm text-gray-700 sm:mt-px sm:pt-2'>
                          Resolution date
                        </label>
                        <FormikCalendar key={'resolutionDate'} fieldName='resolutionDate' />
                      </div>
                    </div>

                    <div id='right pane'>
                      <RiskRatings />
                      <FileUpload organisationId={organisationId} riskId={riskId!} />

                      {filesResult?.files && (
                        <Files key='files' organisationId={organisationId} riskId={riskId!} files={filesResult.files} />
                      )}

                      <SaveAndCancel dirty={dirty} isValid={isValid} cancel={onCancel} />
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>
      )}

      {riskData && (
        <div className='bg-white shadow rounded-lg pt-0 mt-4 p-4'>
          <Notes
            id='notes'
            name='Notes'
            add={handleAddNote}
            remove={handleRemoveNote}
            notes={riskData.notes}
            setNote={setNote}
            note={note}
          />

          <Notes
            id='internalNotes'
            name='Internal Notes'
            add={handleAddInternalNote}
            remove={handleRemoveInternalNote}
            notes={riskData.internalNotes}
            setNote={setInternalNote}
            note={internalNote}
          />
        </div>
      )}
    </>
  )
}
