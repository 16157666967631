import { Link, useNavigate } from 'react-router-dom'

import HttpService from '../../services/http.service'
import { FileModel } from '../../types/FileModel'

interface IProps {
  files: FileModel[]
  navigateTo?: string
  queryParameter?: string
  service?: HttpService
  showView?: boolean
  organisationId: string
  riskId: string
}

export default function Files(props: IProps) {
  let showView = false
  if (props.showView === undefined) {
    showView = true
  }

  const { navigateTo: collection, files } = props

  const navigate = useNavigate()

  function deleteClick(rowId: string) {
    if (props.service) {
      const resourceUrl = `${props.navigateTo}/${rowId}`
      props.service.delete(resourceUrl).then(() => {
        navigate(0)
      })
    }
  }

  function navigateClick(rowId: string) {
    const url = `/${collection}/${rowId}`
    navigate(url, { replace: true })
  }

  if (!files) return <div className='text-base text-neutral-400 mb-6'>no data...</div>

  const storageUrl = 'https://atlasfilestorage.blob.core.windows.net/images/'

  return (
    <div className='flex flex-col max-w-lg'>
      <div className='overflow-x-auto'>
        {files.map((file) => (
          <>
            <div className='float-left border-2 m-1'>
              <a href={`${storageUrl}${file.name}`}>
                <img src={`${storageUrl}${file.name}`} alt={file.name} width='200' height='200'></img>
              </a>
            </div>
          </>
        ))}
      </div>
    </div>
  )
}
