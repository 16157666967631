import { useState } from 'react'
import { useParams } from 'react-router-dom'

import Modal from '../../components/basic/DeleteResourceModal'
import { Table } from '../../components/basic/Table'
import { useAreas } from '../../hooks/useAreas'
import { useAssetsForArea } from '../../hooks/useAssets'
import { ActionBar } from '../../layouts/ActionBar'
import AreaAPI from '../../services/areas.service'
import { Tab } from '../../types/Tab'
import { AddAsset } from '../Site/AddAsset'

export default function Area() {
  const pageSize = 20
  const [page, setPage] = useState<number>(1)
  const [showAddAsset, setShowAddAsset] = useState<boolean>(false)
  const [showDelete, setShowDelete] = useState<boolean>(false)
  const { areaId } = useParams()

  const areaData = useAreas(areaId!, page, pageSize)
  const assetsData = useAssetsForArea(areaId!, page, pageSize)

  const deleteText = 'Are you sure you want to delete this area?'

  const tabs: Tab[] = [
    {
      name: 'Add asset',
      href: '#',
      current: false,
      onClick: () => {
        setShowAddAsset(true)
      },
    },
    {
      name: 'Delete area',
      current: false,
      onClick: () => {
        setShowDelete(true)
      },
    },
  ]

  return (
    <>
      <ActionBar tabs={tabs} classes='py-2' />
      <div id='white-container' className='bg-white shadow rounded-lg p-4'>
        {!showAddAsset && <Table collection='assets' rows={assetsData?.assets ?? []} />}

        {showAddAsset && areaData?.siteId && (
          <AddAsset
            siteId={areaData.siteId}
            onSaveSuccess={() => setShowAddAsset(false)}
            onCancel={() => setShowAddAsset(false)}
          />
        )}

        {showDelete && areaId && areaData && (
          <Modal
            open={showDelete}
            setOpen={setShowDelete}
            label={'Delete site'}
            text={deleteText}
            service={AreaAPI}
            resourceUrl={'areas/'}
            resourceId={areaId}
            redirectUrl={`/sites/${areaData.siteId}`}
          />
        )}
      </div>
    </>
  )
}
