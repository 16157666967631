import { Component } from '../types/Component'
import HttpService from './http.service'

interface GetComponentsResponse {
  components: Component[]
}

class ComponentsAPI extends HttpService {
  getComponent = async (componentId: string): Promise<Component> => {
    return await this.get(`components/${componentId}`)
  }

  getComponents = async (assetId: string, page: number, pageSize: number): Promise<GetComponentsResponse> => {
    return await this.get(`components?assetId=${assetId}&page=${page}&pageSize=${pageSize}`)
  }

  postComponent = async (component: Component) => {
    return await this.post('components', { ...component })
  }

  putComponent = async (component: Component) => {
    return await this.put('components', { ...component })
  }
}

export default new ComponentsAPI({
  apiURL: process.env.REACT_APP_BACKENDURL,
})
