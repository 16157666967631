import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import { FormBuilder } from '../../components/advanced/FormBuilder'
import { useAssetMaintenanceCheck } from '../../hooks/useAssets'
import { useComponents } from '../../hooks/useComponents'
import { Header } from '../../layouts/Header'
import AssetAPI from '../../services/assets.service'
import { Component } from '../../types/Component'
import { Tab } from '../../types/Tab'

export function CurrentCheck() {
  const pageSize = 20
  const [page, setPage] = useState<number>(1)
  const navigate = useNavigate()
  const { assetId } = useParams()

  const { data: asset } = useQuery(['assets', assetId], () => AssetAPI.getAsset(assetId!))

  const organisationId = asset?.organisationId
  const assetMaintenanceCheckResult = useAssetMaintenanceCheck(assetId!)
  const componentsResult = useComponents(assetId!, page, pageSize)

  function getFormData(templateId: string, data: any): any {
    if (data === undefined) {
      return {}
    }

    if (data.checks == null) {
      return {}
    }

    return data.checks.find((x: any) => x.templateId === templateId)
  }

  function publish() {
    AssetAPI.publishAssetMaintenanceCheck(assetId!)
  }

  function Cancel() {
    return (
      <button
        onClick={() => navigate(-1)}
        type='button'
        className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
      >
        Cancel
      </button>
    )
  }

  function Publish() {
    return (
      <button
        onClick={() => publish()}
        type='submit'
        className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
      >
        Publish
      </button>
    )
  }

  function addNewCheck(assetId: string) {
    AssetAPI.postMaintenanceCheck(assetId)
  }

  const tabs = []

  const isLocked = assetMaintenanceCheckResult != null && assetMaintenanceCheckResult.data?.status === 'Published'

  if (isLocked) {
    // If the latest is published, then let the user create a new one.
    tabs.push({
      name: 'New',
      current: false,
      onClick: () => addNewCheck(assetId!),
    })
  }

  return (
    <>
      <Header name={'Current check'} tabs={tabs} />
      <div id='white-container' className='bg-white shadow rounded-lg p-4'>
        <div className='p-2'>
          {organisationId && (
            <>
              {asset.templates.map((t) => (
                <div className='pb-3' key={t}>
                  <FormBuilder
                    organisationId={organisationId}
                    assetId={assetId!}
                    componentId=''
                    formData={getFormData(t, assetMaintenanceCheckResult?.data)}
                    currentTemplateId={t}
                    isLocked={isLocked}
                    // validationSchema={t.validationSchema}
                  />
                </div>
              ))}

              {componentsResult?.components.map((component: Component) => (
                <FormBuilder
                  key={component.id}
                  organisationId={organisationId}
                  assetId={component.assetId}
                  componentId={component.id}
                  formData={getFormData(component.templateId, assetMaintenanceCheckResult?.data)}
                  currentTemplateId={component.templateId}
                  isLocked={isLocked}
                />
              ))}

              <div className='pt-5'>
                <div className='flex justify-end'>
                  {<Cancel />}
                  {<Publish />}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}
