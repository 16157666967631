import { Menu, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { Fragment, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { useUserInfo } from '../hooks/useUserInfo'

export function ProfileDropdown() {
  const { data: user, isLoading } = useUserInfo()
  const navigate = useNavigate()
  const menuItems = useMemo(
    () => [
      // { name: 'Your Profile' },
      // { name: 'Settings' },
      {
        name: 'Sign out',
        onClick: () => {
          window.localStorage.clear()
          navigate('/')
        },
      },
    ],
    [navigate],
  )

  return (
    <Menu as='div' className='ml-3 relative'>
      <div>
        <Menu.Button className='max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
          <span className='sr-only'>Open user menu</span>

          {user && (
            <span className='inline-flex items-center justify-center h-8 w-8 rounded-full bg-gray-500'>
              <span className='text-sm font-medium leading-none text-white'>{getUserInitials(user.name)}</span>
            </span>
          )}
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
          {menuItems.map((item) => (
            <Menu.Item key={item.name}>
              {({ active }) => (
                <button
                  onClick={item.onClick}
                  className={classNames('block px-4 py-2 text-sm text-gray-700', { 'bg-gray-100': active })}
                >
                  {item.name}
                </button>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

const getUserInitials = (name?: string) => {
  return name ? name.split(' ').map((s) => s.charAt(0).toUpperCase()) : null
}
