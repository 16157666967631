/* This example requires Tailwind CSS v2.0+ */
import { Dialog, Transition } from '@headlessui/react'
import { CheckCircleIcon, CheckIcon, InformationCircleIcon } from '@heroicons/react/outline'
import { Fragment, useRef, useState } from 'react'

import { TextArea } from '../basic/TextArea'

interface IProps {
  open: boolean
  handleSubmit: (resolveText: string) => void
  handleCancel: () => void
}

export function ResolveRiskModal(props: IProps) {
  const { open, handleSubmit, handleCancel } = props
  const [resolveText, setResolveText] = useState<string>('')

  function submitAndClearText() {
    handleSubmit(resolveText)
    setResolveText('')
  }

  function CancelAndResolve() {
    return (
      <div className='flex gap-x-1.5 '>
        <div className='grow mt-5 sm:mt-6'>
          <button
            type='button'
            className='inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm'
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
        <div className='grow mt-5 sm:mt-6'>
          <button
            type='button'
            className='inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm'
            onClick={() => submitAndClearText()}
          >
            Resolve
          </button>
        </div>
      </div>
    )
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={handleCancel}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed z-10 inset-0 overflow-y-auto'>
          <div className='flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6'>
                <div>
                  <div className='mt-3 text-center sm:mt-5'>
                    <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900 pb-2'>
                      Resolve risks
                    </Dialog.Title>
                    <TextArea id='resolution' name='' rows={6} onChange={(e) => setResolveText(e.target.value)} />
                  </div>
                </div>
                <CancelAndResolve />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
