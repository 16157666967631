import { Form, Formik } from 'formik'
import { useMutation, useQueryClient } from 'react-query'
import { object, string } from 'yup'

import { SaveAndCancel } from '../../components/basic/SaveAndCancel'
import { TextBox } from '../../components/formik/TextBox'
import OrganisationsAPI from '../../services/organisations.service'

interface IProps {
  onSaveSuccess: () => void
  onCancel: () => void
}

interface OrganisationForm {
  organisationName: string
}

const organisationSchema = object({
  organisationName: string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
})

export function AddOrganisation({ onSaveSuccess, onCancel }: IProps) {
  const queryClient = useQueryClient()
  const initialValues: OrganisationForm = { organisationName: '' }

  const createOrganisation = useMutation((values: OrganisationForm) => {
    return OrganisationsAPI.postOrganisation(values.organisationName)
  })

  const handleSubmit = (values: OrganisationForm): void => {
    createOrganisation.mutate(values, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['organisations'])
        onSaveSuccess()
      },
    })
  }

  return (
    <div className='m-6 space-y-6 divide-y divide-gray-200 sm:space-y-5 max-w-screen-lg'>
      <h3 className='text-lg leading-6 text-gray-900'>Add an organisation</h3>
      <Formik initialValues={initialValues} validationSchema={organisationSchema} onSubmit={handleSubmit}>
        {({ dirty, isValid }) => {
          return (
            <Form>
              <TextBox id='organisationName' description='Organisation name' required />
              <SaveAndCancel dirty={dirty} isValid={isValid} cancel={onCancel} />
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
