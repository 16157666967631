import HttpService from './http.service'

class fileUploadAPI extends HttpService {
  getFiles = async (siteId: string, page: number, pageSize: number) => {
    return await this.get(`files?organisationId=${siteId}&page=${page}&pageSize=${pageSize}`)
  }

  postFile = async (organisationId: string, riskId: string, fileName: string, base64: any) => {
    console.log('blah')
    return await this.post('files', { organisationId, fileName, riskId, base64 })
  }
}

export default new fileUploadAPI({
  apiURL: process.env.REACT_APP_FILEUPLOAD_URL,
})
