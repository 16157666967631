import { useEffect, useState } from 'react'
import { Link, Outlet, useNavigate } from 'react-router-dom'

import { Logo } from '../assets/Logo'
import { useAuthInit } from '../helpers/authInit'
import { BreadCrumb } from '../layouts/BreadCrumb'
import Search from '../layouts/Search'
import { SideBar } from '../layouts/SideBar'
import { ErrorPopup } from './popups/ErrorPopup'
import { SuccessPopup } from './popups/SuccessPopup'
import { ProfileDropdown } from './ProfileDropdown'

export function Layout() {
  const [mobileSideBarOpen, setMobileSideBarOpen] = useState(true)
  const { isAuthorized, logOut } = useAuthInit()
  const navigate = useNavigate()

  const [successPopupIsVisible, setSuccessPopupIsVisible] = useState(true)
  const [errorPopupIsVisible, setErrorPopupIsVisible] = useState(true)

  !isAuthorized && navigate('/sign-in')

  setTimeout(() => {
    setSuccessPopupIsVisible(false)
  }, 3000)

  setTimeout(() => {
    setErrorPopupIsVisible(false)
  }, 3000)

  const closeSuccessPopUp = () => {
    setSuccessPopupIsVisible(false)
  }

  const closeErrorPopUp = () => {
    setErrorPopupIsVisible(false)
  }

  return (
    <>
      <div className='container mx-auto px-4 sm:px-6 lg:px-8'></div>
      <div className='relative min-h-screen flex flex-col'>
        <header className=' flex-shrink-0 shadow-md px-2 sm:px-4 lg:px-8 relative flex items-center justify-between h-16'>
          <div className='hidden sm:flex items-center px-2 lg:px-0  h-8 w-auto '>
            <Link to='/'>
              <Logo width={33} height={33} />
            </Link>
          </div>

          <div className='sm:hidden  cursor-pointer' onClick={() => setMobileSideBarOpen(!mobileSideBarOpen)}>
            <Logo width={33} height={33} />
          </div>

          <Search />

          <div className='flex hidden lg:block items-center justify-end'>
            <ProfileDropdown />
          </div>
        </header>

        <BreadCrumb key={window.location.pathname} />

        <div id='flex-container' className='flex-grow w-full flex '>
          <SideBar mobileSideBarOpen={mobileSideBarOpen} />
          <main className='shadow-inner flex-grow'>
            <div className=' shadow-inner h-full p-4 sm:px-2 lg:px-4 bg-slate-100'>
              <Outlet />
            </div>
          </main>
        </div>
      </div>

      <ErrorPopup isVisible={errorPopupIsVisible} closePopUp={closeErrorPopUp} />
      <SuccessPopup isVisible={successPopupIsVisible} closePopUp={closeSuccessPopUp} />
    </>
  )
}

export default Layout
