import * as yup from 'yup'

export const loginSchema = yup
  .object()
  .shape({
    email: yup.string().email().max(120).required('email is required'),
    password: yup.string().min(8).max(80).required('password is required'),
  })
  .required()

export type TLoginForm = yup.InferType<typeof loginSchema>

export const registerSchema = yup
  .object()
  .shape({
    firstName: yup.string().min(2).max(120).required('first name is required'),
    lastName: yup.string().min(2).max(120).required('last name is required'),
    email: yup.string().email().max(120).required('email is required'),
    password: yup.string().min(8).max(80).required('password is required'),
    repeatPassword: yup.string().test('passwords-match', 'Passwords must match', function (value) {
      return this.parent.password === value
    }),
  })
  .required()

export type TRegisterForm = yup.InferType<typeof registerSchema>
