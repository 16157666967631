import HttpService from './http.service'

export interface GetLoginResponse {
  token: string
}

class AuthAPI extends HttpService {
  login = async (data: { email: string; password: string }): Promise<GetLoginResponse> => {
    return this.post('Logins', data)
  }

  register = (data: { email: string; password: string; firstName: string; lastName: string }) => {
    return this.post('UserAccounts', data)
  }
}

export default new AuthAPI({
  apiURL: process.env.REACT_APP_BACKENDURL,
})
