import { Organisation } from '../types/Organisation'
import HttpService from './http.service'

interface GetNodesResponse {
  nodes: Node[]
  links: Link[]
}

interface Node {
  name: string
}

interface Link {
  source: number
  target: number
}

class GraphAPI extends HttpService {
  getNodes = async (): Promise<GetNodesResponse> => {
    return await this.get('Graphs/nodes')
  }
}

export default new GraphAPI({
  apiURL: process.env.REACT_APP_BACKENDURL,
})
