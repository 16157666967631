export function useAuthInit() {
  const accessToken = localStorage.getItem('AUTH_TOKEN_KEY')

  const logOut = () => {
    if (accessToken) {
      localStorage.removeItem('AUTH_TOKEN_KEY')
    }
  }

  return {
    isAuthorized: !!localStorage.getItem('AUTH_TOKEN_KEY'),
    logOut,
  }
}
