import { useQuery } from 'react-query'

import OrganisationsAPI from '../services/organisations.service'

export const useOrganisation = (organisationId: string) => {
  const { data: organisationData } = useQuery(
    ['organisations', organisationId],
    async () => await OrganisationsAPI.getOrganisation(organisationId!),
  )

  return organisationData
}

export const useOrganisations = (page: number, pageSize: number) => {
  const { data } = useQuery(
    ['organisations', { page, pageSize }],
    async () => await OrganisationsAPI.getOrganisations(page, pageSize),
  )

  return data
}
