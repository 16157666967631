//
import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { ReactSortable } from 'react-sortablejs'

import { getTheme, ThemeContext } from '../../hooks/useTheme'
import { Row, SelectableRow } from '../../types/Row'

interface IProps {
  rows: Row[]
  onSorted(rows: Row[]): void
  collection?: string
  queryParameter?: string
}

export function SortableTable(props: IProps) {
  const theme = useContext(ThemeContext)

  const { collection, rows, onSorted } = props
  let isNavigatable = false
  if (collection != null) {
    isNavigatable = true
  }

  if (!rows) return <div className='text-base text-neutral-400 mb-6'>no data...</div>

  return (
    <div className='flex flex-col max-w-2xl'>
      <div className='overflow-x-auto'>
        <div className='py-2 align-middle inline-block min-w-full'>
          <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-lg'>
            <div className='table-auto min-w-full divide-gray-200'></div>
            <div className='bg-blue-900'>
              <div className='px-4 py-2 text-left text-sm text-white font-bold'>Name</div>
            </div>
            <div className='bg-slate-50 divide-y divide-gray-200 border   '>
              <ReactSortable list={rows} setList={onSorted} delay={2}>
                {rows.map((row) => (
                  <div key={row.id} className='px-4 py-2 whitespace-nowrap text-sm text-gray-900 bg-white border-b '>
                    {isNavigatable && <Link to={`/${collection}/${row.id}`}>{row.name}</Link>}
                    {!isNavigatable && row.name}
                  </div>
                ))}
              </ReactSortable>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
