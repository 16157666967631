import { number, object, ref, string } from 'yup'

function validate(val?: string, context?: any): boolean {
  if (val === null || val === undefined) {
    if (context === null || context === undefined) {
      return false
    }
  }

  return true
}

export const HoseSchema = object({
  end1PartNumber: string().test('End1', 'Part number or Description', (val, context) =>
    validate(val, context.parent.end1Description),
  ),
  materialPartNumber: string().test('Material', 'Part number or Description', (val, context) =>
    validate(val, context.parent.materialDescription),
  ),
  end2PartNumber: string().test('End2', 'Part number or Description', (val, context) =>
    validate(val, context.parent.end2Description),
  ),

  orientation: number().min(0).max(360).required('Required'),
  conditionScore: number().min(0).max(10).required('Required'),
})
