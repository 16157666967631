interface IProps {
  id: string
  name: string
  handleChange: (e: any) => void
  value?: string
  type?: string
  isReadOnly?: boolean
  disabled?: boolean
}

export default function TextBox(props: IProps) {
  const { id, name, value, type, handleChange, isReadOnly, disabled } = props

  const disabledLabel = ' pointer-events-none'
  const disabledInput = ' text-gray-500 border-gray-400 pointer-events-none bg-gray-100'

  return (
    <div>
      <label htmlFor={id} className={'block text-sm text-gray-700 sm:mt-px sm:pt-2' + (disabled && disabledLabel)}>
        {name}
      </label>
      <div className='sm:col-span-2'>
        <input
          id={id}
          name={name}
          onChange={handleChange}
          type={type === 'number' ? 'number' : 'text'}
          className={
            'max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md' +
            (disabled && disabledInput)
          }
          readOnly={isReadOnly}
          value={value}
        />
      </div>
    </div>
  )
}
