import { SearchIcon } from '@heroicons/react/solid'
import { debounce } from 'lodash'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'

import SearchesAPI from '../services/searches.service'

export default function Search() {
  const [searchText, setSearchText] = useState('')

  const { data: searchResults } = useQuery(
    ['searches', searchText],
    async () => await SearchesAPI.search(searchText),
    {},
  )

  const assets = searchResults?.items ? searchResults.items.filter((x) => x.type === 'Area') : []
  const areas = searchResults?.items ? searchResults.items.filter((x) => x.type === 'Asset') : []

  return (
    <div className='flex-1 flex justify-center lg:justify-end z-10 max-w-4xl	'>
      <div className='w-full px-2 lg:px-6'>
        <label htmlFor='search' className='sr-only'>
          Search
        </label>
        <div className='relative text-indigo-200 focus-within:text-gray-400'>
          <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
            <SearchIcon className='h-5 w-5' aria-hidden='true' />
          </div>
          <input
            id='search'
            name='search'
            className={
              ' block w-full pl-10 pr-3 py-2 border-indigo-200 border rounded-md leading-5 bg-opacity-25 text-indigo-100 placeholder-indigo-200 focus:outline-none focus:bg-white focus:ring-0 focus:placeholder-gray-400 focus:text-gray-900 sm:text-sm'
            }
            placeholder='Search'
            type='search'
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>

        {searchResults && searchResults.items.length > 1 && (
          <div className='absolute bg-white shadow mt-2 p-2'>
            {assets.length > 1 && (
              <div>
                <h3 className='bg-slate-100'>Assets</h3>
                <ul>
                  {assets.map((x) => (
                    <li>
                      <Link key={x.id} to={`/${x.type?.toLowerCase()}s/${x.id}`}>
                        {x.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {areas.length > 1 && (
              <div>
                <h3 className='bg-slate-100'>Areas</h3>
                <ul>
                  {areas.map((x) => (
                    <li>
                      <Link key={x.id} to={`/${x.type?.toLowerCase()}s/${x.id}`}>
                        {x.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
