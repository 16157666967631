import { ChevronRightIcon } from '@heroicons/react/solid'
import { useQuery } from 'react-query'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

import AreasAPI from '../services/areas.service'
import AssetsAPI from '../services/assets.service'
import OrganisationsAPI from '../services/organisations.service'
import SiteAPI from '../services/sites.service'
import { Component } from '../types/Component'

export interface IBreadCrumbProps {
  component?: Component
}

export function BreadCrumb({ component }: IBreadCrumbProps) {
  const pages: { name: string; href: string }[] = []

  const location = useLocation()
  const { assetId } = useParams()
  let { areaId, organisationId, siteId } = useParams()

  const { data: assetData } = useQuery(['assets', assetId], async () => await AssetsAPI.getAsset(assetId!), {
    enabled: !!assetId,
  })

  if (assetData) {
    areaId = assetData.areaId
    siteId = assetData.siteId
  }

  const { data: areaData } = useQuery(['areas', areaId], async () => await AreasAPI.getArea(areaId!), {
    enabled: !!areaId,
  })

  if (areaData) {
    siteId = areaData.siteId
  }

  const { data: siteData } = useQuery(['sites', siteId], async () => await SiteAPI.getSite(siteId!), {
    enabled: !!siteId,
  })

  if (siteData) {
    organisationId = siteData.organisationId
  }

  const { data: organisationResponse } = useQuery(
    ['organisations', organisationId],
    async () => await OrganisationsAPI.getOrganisation(organisationId!),
    {
      enabled: !!organisationId,
    },
  )

  if (location.pathname.length > 2) {
    pages.push({ name: 'organisations', href: '/organisations' })
  }

  if (organisationId && organisationResponse) {
    pages.push({ name: organisationResponse?.organisation.name, href: `/organisations/${organisationId}` })
  }

  if (siteId && siteData) {
    pages.push({ name: siteData.name, href: `/sites/${siteId}` })
  }

  if (areaId && areaData) {
    pages.push({ name: areaData.name, href: `/areas/${areaId}` })
  }

  if (assetId && assetData) {
    pages.push({ name: assetData.name, href: `/assets/${assetId}` })
  }

  if (component) {
    pages.push({ name: component.name, href: `/components/${component.id}` })
  }

  return (
    <nav className='sm:ml-5 py-2 lowercase hidden sm:flex' aria-label='Breadcrumb'>
      <ol className='flex items-center space-x-1'>
        <li>
          <div className='flex items-center'>
            <Link key='home' to='/' className='text-sm text-gray-500 hover:text-gray-700'>
              home
            </Link>
          </div>
        </li>

        {pages.map((page: { name: string; href: string }) => (
          <li key={page.name}>
            <div className='flex items-center'>
              <ChevronRightIcon className='flex-shrink-0 h-5 w-5 text-gray-400' aria-hidden='true' />
              <Link key={page.name} to={page.href} className='text-sm text-gray-500 hover:text-gray-700'>
                {page.name}
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  )
}
