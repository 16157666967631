import { Hose } from '../types/Hose'
import HttpService from './http.service'

interface GetHosesResponse {
  hoses: Hose[]
}

class HosesAPI extends HttpService {
  getHoses = async (assetId: string, page: number, pageSize: number): Promise<GetHosesResponse> => {
    return await this.get(`hoses/?assetId=${assetId}&page=${page}&pageSize=${pageSize}`)
  }

  getHose = async (hoseId: string): Promise<Hose> => {
    return await this.get(`hoses/${hoseId}`)
  }

  putHose = async (hose: Hose) => {
    return await this.post('hoses', { ...hose })
  }

  postHose = async (hose: Hose) => {
    return await this.post('hoses', { ...hose })
  }
}

export default new HosesAPI({
  apiURL: process.env.REACT_APP_BACKENDURL,
})
