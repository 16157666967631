import { Form, Formik } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'

import { SaveAndCancel } from '../../../components/basic/SaveAndCancel'
import { useAsset } from '../../../hooks/useAssets'
import HoseAPI from '../../../services/hoses.service'
import { Hose } from '../../../types/Hose'
import { HoseForm } from './HoseForm'
import { HoseSchema } from './ValidateHose'

interface IProps {
  setVisibility: () => void
}

export function AddHose(props: IProps) {
  const navigate = useNavigate()

  const { assetId } = useParams()
  const assetData = useAsset(assetId!)

  const initialValues: Hose = {
    organisationId: '',
    end1PartNumber: '',
    end1Description: '',
    materialPartNumber: '',
    materialDescription: '',
    end2PartNumber: '',
    end2Description: '',
    assetId: '',
    conditionScore: undefined,
    lastChangedDate: '',
  }

  const handleSubmit = (hose: Hose): void => {
    if (!assetId) return

    hose.assetId = assetId
    hose.organisationId = assetData!.organisationId!
    HoseAPI.postHose({ ...hose }).then(() => {
      navigate(0)
    })
  }

  return (
    <>
      <h3 className='text-lg leading-6 text-gray-900'>Add a hose</h3>
      <Formik
        initialValues={initialValues}
        validateOnChange={true}
        validationSchema={HoseSchema}
        onSubmit={handleSubmit}
      >
        {({ dirty, isValid, values }) => {
          return (
            <Form>
              <HoseForm />
              <SaveAndCancel dirty={dirty} isValid={isValid} cancel={props.setVisibility} />
            </Form>
          )
        }}
      </Formik>
    </>
  )
}
