import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { Link, useNavigate } from 'react-router-dom'

import { Logo } from '../../assets/Logo'
import { loginSchema, TLoginForm } from '../../constants/auth.types'
import AuthAPI, { GetLoginResponse } from '../../services/auth.service'

export default function SignIn() {
  const navigate = useNavigate()

  // const [loginError, setLoginError] = useState<string>()
  const [loginError, setLoginError] = useState<boolean>(false)

  const [passwordType, setPasswordType] = useState('password')
  const togglePassword = () => {
    if (passwordType === 'password') {
      setPasswordType('text')
      return
    }
    setPasswordType('password')
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(loginSchema) })

  const mutation = useMutation(AuthAPI.login)
  // const { mutate, isError: apiLoginError, isSuccess: loginSuccess } = mutation
  const { mutate, isError, isSuccess } = mutation

  // const onSubmit = async (values: TLoginForm) => {
  //   await mutate(
  //     { email: values.email, password: values.password },
  //     {
  //       onSuccess: async (response: GetLoginResponse) => {
  //         localStorage.setItem('AUTH_TOKEN_KEY', response.token)
  //         navigate('/')
  //       },
  //       onError: async (error, variables, context) => {
  // setLoginError('Incorrect email or password')
  //       },
  //     },
  //   )
  // }

  const onSubmit = async (values: TLoginForm) => {
    const ErrorMessage = setTimeout(() => {
      setLoginError(true)
    }, 500)
    mutate(
      { email: values.email, password: values.password },
      {
        onSuccess: (response) => {
          localStorage.setItem('AUTH_TOKEN_KEY', response.token)
          clearTimeout(ErrorMessage)
          setLoginError(false)
          navigate('/')
        },
      },
    )
  }

  function Password() {
    return (
      <div className='space-y-1'>
        <label htmlFor='password' className='block text-sm font-medium text-gray-700'>
          Password
        </label>
        <div className='mt-1 relative'>
          <input
            {...register('password')}
            id='password'
            name='password'
            type={passwordType}
            autoComplete='current-password'
            className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
          />
          <button
            type='button'
            onClick={togglePassword}
            className='block absolute inset-y-0 right-0 pr-3 flex items-center'
          >
            {passwordType === 'password' ? (
              <EyeIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
            ) : (
              <EyeOffIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
            )}
          </button>

          {/* <p className='text-red-500 text-sm'>{errors.password?.message}</p> */}
          {/* {loginError && <p className='text-red-500 text-sm'>{loginError}</p>} */}
          {/* {loginError && <p className='text-red-500 text-sm'>Email or password is incorrect</p>} */}
          {loginError && (
            <p className='absolute botton-0 left-0 text-red-500 text-sm font-medium pt-1'>
              Email or password is incorrect
            </p>
          )}
        </div>
      </div>
    )
  }

  function Email() {
    return (
      <div>
        <label htmlFor='email' className='block text-sm font-medium text-gray-700'>
          Email address
        </label>
        <div className='mt-1'>
          <input
            {...register('email')}
            id='email'
            autoComplete='email'
            className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
          />
          <p className='text-red-500 text-sm'>{errors.email?.message}</p>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className='min-h-full flex'>
        <div className='flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24'>
          <div className='mx-auto w-full max-w-sm lg:w-96'>
            <div>
              <Logo width={340} height={340} />
              <h2 className='mt-6 text-3xl font-extrabold text-gray-900'>Sign in to your account</h2>
              <p className='mt-2 text-sm text-gray-600'>
                {/* Or{' '}
                <Link to='/sign-up' className='text-indigo-600 hover:text-indigo-500'>
                  create a new account
                </Link> */}
              </p>
            </div>

            <div className='mt-8'>
              <div className='mt-6'>
                <form onSubmit={handleSubmit(onSubmit)} className='space-y-6'>
                  <Email />
                  <Password />

                  <div className='flex items-center justify-between'>
                    <div className='flex items-center'>
                      <input
                        id='remember-me'
                        name='remember-me'
                        type='checkbox'
                        className='h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded'
                      />
                      <label htmlFor='remember-me' className='ml-2 block text-sm '>
                        Remember me
                      </label>
                    </div>

                    <div className='text-sm'>
                      <a href='#' className='font-medium text-indigo-600 hover:text-indigo-500'>
                        Forgot your password?
                      </a>
                    </div>
                  </div>

                  <div>
                    <button
                      type='submit'
                      className='w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    >
                      Sign in
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className='hidden lg:block relative w-0 flex-1'>
          <img
            className='absolute inset-0 h-full w-full object-cover'
            src='https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80'
            alt=''
          />
        </div>
      </div>
    </>
  )
}
