interface IProps {
  width: number
  height: number
}

export function Logo(props: IProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={props.width}
      height={props.height}
      version='1.1'
      viewBox='0 0 433 433'
      xmlSpace='preserve'
    >
      <g transform='matrix(4.29 0 0 4.29 216.87 217.13)'>
        <path
          fill='#2e55aa'
          fillRule='nonzero'
          stroke='#000'
          strokeDasharray='none'
          strokeDashoffset='0'
          strokeLinecap='butt'
          strokeLinejoin='miter'
          strokeMiterlimit='4'
          strokeWidth='0'
          d='M50 0A50 50 0 010 50 50 50 0 01-50 0 50 50 0 010-50 50 50 0 0150 0z'
          opacity='1'
        ></path>
      </g>
      <g transform='matrix(3.62 0 0 3.62 217 217.27)'>
        <path
          fill='#fff'
          fillRule='nonzero'
          stroke='#000'
          strokeDasharray='none'
          strokeDashoffset='0'
          strokeLinecap='butt'
          strokeLinejoin='miter'
          strokeMiterlimit='4'
          strokeWidth='0'
          d='M50 0A50 50 0 010 50 50 50 0 01-50 0 50 50 0 010-50 50 50 0 0150 0z'
          opacity='1'
        ></path>
      </g>
      <g transform='translate(195.78 230.92)'>
        <g
          style={{ whiteSpace: 'pre' }}
          fill='#2e55aa'
          fillRule='nonzero'
          stroke='none'
          strokeDasharray='none'
          strokeDashoffset='0'
          strokeLinecap='butt'
          strokeLinejoin='miter'
          strokeMiterlimit='4'
          strokeWidth='1'
          aria-label='M'
          fontFamily='Ubuntu'
          fontSize='310'
          fontStyle='italic'
          fontWeight='normal'
          opacity='1'
        >
          <path d='M111.45-71.26q-4.96 6.82-12.4 17.67-7.44 10.85-16.12 23.87Q74.25-16.7 64.95-2.44q-9.3 13.95-17.98 27.28-8.68 13.02-16.12 24.49-7.44 11.16-12.09 18.29H-5.42q-1.24-7.44-3.41-18.6-1.86-11.47-4.34-24.49-2.48-13.33-5.27-27.28-2.48-14.26-5.27-27.28-2.48-13.02-4.65-23.56-2.17-10.85-3.72-17.67Q-45.1-31.27-57.5 11.2q-12.4 42.47-24.18 86.18h-29.76q7.44-27.9 15.5-56.11 8.37-28.52 16.74-55.8 8.68-27.59 17.05-53.63 8.68-26.04 16.74-49.29h29.45q3.72 13.64 7.75 32.24 4.34 18.6 8.37 39.06Q4.5-26 7.91-5.85q3.72 20.15 6.2 36.58Q25.27 13.99 38.6-6.16l27.28-40.3q13.64-20.46 26.35-38.75 13.02-18.6 23.25-32.24h28.83q-5.27 52.39-14.26 106.64-8.68 53.94-19.22 108.19H80.76q9.3-44.64 17.05-86.49 7.75-42.16 13.64-82.15z'></path>
        </g>
      </g>
    </svg>
  )
}
