import { Form, Formik } from 'formik'
import { useMutation, useQueryClient } from 'react-query'
import * as yup from 'yup'

import { SaveAndCancel } from '../../components/basic/SaveAndCancel'
import { TextBox } from '../../components/formik/TextBox'
import AreasAPI from '../../services/areas.service'

interface IProps {
  onSaveSuccess: () => void
  onCancel: () => void
  siteId: string
}

interface AreaForm {
  areaName: string
}

const areaSchema = yup.object({
  areaName: yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
})

export function AddArea({ siteId, onSaveSuccess, onCancel }: IProps) {
  const queryClient = useQueryClient()
  const initialValues: AreaForm = { areaName: '' }

  const createAsset = useMutation((values: AreaForm) => {
    return AreasAPI.postArea(siteId, values.areaName)
  })

  const handleSubmit = (values: AreaForm): void => {
    createAsset.mutate(values, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['areas', siteId])
        onSaveSuccess()
      },
    })
  }

  return (
    <div className='space-y-8 divide-y divide-gray-200'>
      <div className='space-y-8 divide-y divide-gray-200 sm:space-y-5'>
        <div className='pt-8 space-y-6 sm:pt-10 sm:space-y-5'>
          <div>
            <h3 className='text-lg leading-6 text-gray-900'>Add an area</h3>
          </div>
          <div>
            <Formik initialValues={initialValues} validationSchema={areaSchema} onSubmit={handleSubmit}>
              {({ dirty, isValid }) => {
                return (
                  <Form>
                    <TextBox id='areaName' description='Area name' required />
                    <SaveAndCancel dirty={dirty} isValid={isValid} cancel={onCancel} />
                  </Form>
                )
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}
