import { VscCheck, VscChromeClose } from 'react-icons/vsc'

interface IProps {
  handleChange: (e: any) => void
  value?: string
  isReadOnly?: boolean
  disabled?: boolean
}

export function ButtonGroupWithNotApplicable(props: IProps) {
  const { value, handleChange, isReadOnly, disabled } = props

  const disabledButton = ' border-gray-400 pointer-events-none bg-gray-100'

  function handleClick(e: any) {
    if (!isReadOnly) {
      handleChange(e)
    }
  }

  function NAButton() {
    return (
      <button
        onClick={handleClick}
        type='button'
        className={
          'relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-xs text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500' +
          (disabled && disabledButton)
        }
        value='notApplicable'
      >
        N/A
      </button>
    )
  }

  function PassButton() {
    return (
      <button
        onClick={handleClick}
        type='button'
        className={
          '-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-green-600 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500' +
          (disabled && disabledButton)
        }
        value='true'
      >
        <VscCheck />
      </button>
    )
  }

  function FailButton() {
    return (
      <button
        onClick={handleClick}
        type='button'
        className={
          '-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-red-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500' +
          (disabled && disabledButton)
        }
        value='false'
      >
        <VscChromeClose />
      </button>
    )
  }

  function SelectedNAButton() {
    return (
      <button
        onClick={handleClick}
        type='button'
        className={
          'relative inline-flex items-center px-4 py-2 rounded-l-md border bg-gray-300 text-xs text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500' +
          (disabled && disabledButton)
        }
        value='notApplicable'
      >
        N/A
      </button>
    )
  }

  function SelectedPassButton() {
    return (
      <button
        onClick={handleClick}
        type='button'
        className={
          '-ml-px relative inline-flex items-center px-4 py-2 border bg-gray-300  text-sm font-medium text-green-600 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500' +
          (disabled && disabledButton)
        }
        value='true'
      >
        <VscCheck />
      </button>
    )
  }

  function SelectedFailButton() {
    return (
      <button
        onClick={handleClick}
        type='button'
        className={
          '-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 text-sm font-medium text-red-500 bg-gray-300 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500' +
          (disabled && disabledButton)
        }
        value='false'
      >
        <VscChromeClose />
      </button>
    )
  }

  return (
    <span className='relative z-0 inline-flex shadow-sm rounded-md'>
      {value === 'notApplicable' ? <SelectedNAButton /> : <NAButton />}
      {value === 'true' ? <SelectedPassButton /> : <PassButton />}
      {value === 'false' ? <SelectedFailButton /> : <FailButton />}
    </span>
  )
}
