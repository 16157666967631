import { Item } from '../types/Item'
import HttpService from './http.service'

export interface SearchesResponse {
  items: Item[]
}

export interface SearchRequest {
  organisationId?: string
  keywords?: string
  siteIds?: string[]
  areaIds?: string[]
  types?: string[]
}

class SearchesAPI extends HttpService {
  search = async (keywords: string): Promise<SearchesResponse> => {
    const searchRequest: SearchRequest = {
      keywords: keywords,
    }
    return await this.post('Searches/', { ...searchRequest })
  }

  searchOrganisations = async (keywords: string): Promise<SearchesResponse> => {
    const searchRequest: SearchRequest = {
      keywords: keywords,
      types: ['organisations'],
    }
    return await this.post('Searches/', { ...searchRequest })
  }

  searchSites = async (organisationId: string, keywords: string): Promise<SearchesResponse> => {
    const searchRequest: SearchRequest = {
      organisationId: organisationId,
      keywords: keywords,
      types: ['site'],
    }
    return await this.post('Searches/', { ...searchRequest })
  }

  searchAreas = async (organisationId: string, siteIds?: string[], keywords?: string): Promise<SearchesResponse> => {
    const searchRequest: SearchRequest = {
      organisationId: organisationId,
      keywords: keywords,
      siteIds: siteIds,
      types: ['area'],
    }
    return await this.post('Searches/', { ...searchRequest })
  }

  searchAssets = async (
    organisationId: string,
    siteIds?: string[],
    areaIds?: string[],
    keywords?: string,
  ): Promise<SearchesResponse> => {
    const searchRequest: SearchRequest = {
      organisationId: organisationId,
      siteIds: siteIds,
      areaIds: areaIds,
      keywords: keywords,
      types: ['asset'],
    }

    return await this.post('Searches/', { ...searchRequest })
  }

  searchComponents = async (organisationId: string, keywords: string): Promise<SearchesResponse> => {
    const searchRequest: SearchRequest = {
      organisationId: organisationId,
      keywords: keywords,
      types: ['component'],
    }
    return await this.post('Searches/', { ...searchRequest })
  }
}

export default new SearchesAPI({
  apiURL: process.env.REACT_APP_BACKENDURL,
})
