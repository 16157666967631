import { useQuery } from 'react-query'

import SearchesAPI from '../services/searches.service'

export const useSiteSearches = (organisationId: string) => {
  const { data: siteOptions } = useQuery(
    ['siteSearches', organisationId],
    async () => await SearchesAPI.searchSites(organisationId, ''),
    {
      enabled: !!organisationId,
    },
  )

  return siteOptions
}

export const useAreaSearches = (organisationId: string, selectedSites: string[]) => {
  const { data: areaOptions } = useQuery(
    ['areaSearches', organisationId, selectedSites],
    async () => await SearchesAPI.searchAreas(organisationId, selectedSites, ''),
    {
      enabled: !!organisationId,
    },
  )
  return areaOptions
}

export const useAssetSearches = (organisationId: string, selectedSites: string[], selectedAreas: string[]) => {
  const { data: assetOptions } = useQuery(
    ['assetSearches', organisationId, selectedSites, selectedAreas],
    async () => await SearchesAPI.searchAssets(organisationId, selectedSites, selectedAreas, ''),
    {
      enabled: !!organisationId,
    },
  )
  return assetOptions
}
