import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { Link, useNavigate } from 'react-router-dom'

import { registerSchema, TRegisterForm } from '../../constants/auth.types'
import AuthAPI from '../../services/auth.service'
import logo from '../images/logo.svg'

export default function SignUp() {
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(registerSchema),
  })

  const mutation = useMutation(AuthAPI.register)
  const { mutate, isError: loginError, isSuccess: loginSuccess } = mutation

  const onSubmit = async (values: TRegisterForm) => {
    await mutate(
      {
        email: values.email,
        password: values.password,
        firstName: values.firstName,
        lastName: values.lastName,
      },
      {
        onSuccess: (token: string) => {
          localStorage.setItem('AUTH_TOKEN_KEY', token)
          navigate('/')
        },
      },
    )
  }

  return (
    <>
      <div className='min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8'>
        <div className='sm:mx-auto sm:w-full sm:max-w-md'>
          <img src='logo.svg' className='mx-auto h-12 w-auto caret-slate-300' alt='logo' />
          <h2 className='mt-6 text-center text-3xl font-extrabold text-gray-900'>Create an account</h2>
          <p className='mt-2 text-center text-sm text-gray-600'>
            Or{' '}
            <Link to='/sign-in' className='font-medium text-indigo-600 hover:text-indigo-500'>
              sign in to your account
            </Link>
          </p>
        </div>

        <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
          <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>
            <form className='space-y-6' onSubmit={handleSubmit(onSubmit)}>
              <div>
                <label htmlFor='firstName' className='block text-sm font-medium text-gray-700'>
                  First name
                </label>
                <div className='mt-1'>
                  <input
                    id='firstName'
                    {...register('firstName')}
                    className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                  />
                  <p className='text-red-500 text-sm'>{errors.firstName?.message}</p>
                </div>
              </div>

              <div>
                <label htmlFor='lastName' className='block text-sm font-medium text-gray-700'>
                  Last name
                </label>
                <div className='mt-1'>
                  <input
                    id='lastName'
                    {...register('lastName')}
                    className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                  />
                  <p className='text-red-500 text-sm'>{errors.lastName?.message}</p>
                </div>
              </div>

              <div>
                <label htmlFor='email' className='block text-sm font-medium text-gray-700'>
                  Email address
                </label>
                <div className='mt-1'>
                  <input
                    id='email'
                    {...register('email')}
                    autoComplete='email'
                    className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                  />
                  <p className='text-red-500 text-sm'>{errors.email?.message}</p>
                </div>
              </div>

              <div>
                <label htmlFor='password' className='block text-sm font-medium text-gray-700'>
                  Password
                </label>
                <div className='mt-1'>
                  <input
                    id='password'
                    type='password'
                    {...register('password')}
                    className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                  />
                  <p className='text-red-500 text-sm'>{errors.password?.message}</p>
                </div>
              </div>

              <div>
                <label htmlFor='password' className='block text-sm font-medium text-gray-700'>
                  Repeat password
                </label>
                <div className='mt-1'>
                  <input
                    {...register('repeatPassword')}
                    id='repeatPassword'
                    type='password'
                    className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                  />
                  <p className='text-red-500 text-sm'>{errors.repeatPassword?.message}</p>
                </div>
              </div>

              <div>
                <button
                  type='submit'
                  className='w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                >
                  Create an account
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
