import { Form, Formik } from 'formik'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { object, string } from 'yup'

import { SaveAndCancel } from '../../../components/basic/SaveAndCancel'
import { FormikSelect } from '../../../components/formik/FormikSelect'
import { TextBox } from '../../../components/formik/TextBox'
import { useAsset } from '../../../hooks/useAssets'
import templates from '../../../services/component-templates.json'
import ComponentsAPI from '../../../services/components.service'
import SiteAPI from '../../../services/sites.service'
import { Component } from '../../../types/Component'
import { IValue } from '../../../types/IValues'

interface IProps {
  setVisibility: () => void
}

export function AddComponent(props: IProps) {
  const { assetId } = useParams()
  const navigate = useNavigate()

  const assetData = useAsset(assetId!)

  const componentSchema = object({
    name: string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
    modelCode: string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
  })

  const { data: siteData } = useQuery(
    ['sites', assetData?.siteId],
    async () => await SiteAPI.getSite(assetData!.siteId),
    {
      enabled: !!assetData?.siteId,
    },
  )

  const initialValues: Component = {
    id: '',
    name: '',
    assetId: '',
    templateId: '',
    functionalLocation: '',
    modelCode: '',
    manufacturer: '',
    quantity: 1,
    description: '',
    category: '',
  }

  const typedTemplates: IValue[] = templates.map((x) => {
    return { value: x.id, label: x.name }
  })

  const componentCategories: IValue[] = [
    { value: 'Accumulator', label: 'Accumulator' },
    { value: 'Valve', label: 'Valve' },
    { value: 'Hose', label: 'Hose' },
    { value: 'Pump', label: 'Pump' },
    { value: 'Motor', label: 'Motor' },
    { value: 'TranducerAndGauges', label: 'Tranducer and Gauges' },
    { value: 'Filter', label: 'Filter' },
    { value: 'CylinderAndActuators', label: 'Cylinder and Actuators' },
    { value: 'Driveline', label: 'Driveline' },
    { value: 'Hardware', label: 'Hardware' },
  ]

  const handleSubmit = async (values: Component): Promise<any> => {
    if (!assetId) return

    const functionalLocation = generateFunctionalLocation(values)
    await ComponentsAPI.postComponent({ ...values, assetId, functionalLocation })
    navigate(0)
  }

  function generateFunctionalLocation(values: any) {
    const assetName = assetData?.name ? truncate(assetData?.name, 4).toLowerCase() : ''
    const siteName = siteData?.name ? truncate(siteData?.name, 4).toLowerCase() : ''

    const category = values.category ? truncate(values.category, 1).toLowerCase() : ''

    return `${siteName}-${assetName}-${values?.name}-${category}${
      values.quantity > 1 ? `-n${values.quantity}` : ''
    }`.replace(/ /g, '')
  }

  function truncate(str: string, maxlength: number) {
    return str.length > maxlength ? str.slice(0, maxlength) : str
  }

  return (
    <>
      <h3 className='text-lg text-gray-900'>Add a component</h3>
      <div>
        <Formik initialValues={initialValues} validationSchema={componentSchema} onSubmit={handleSubmit}>
          {({ dirty, isValid, values }) => {
            return (
              <Form>
                <TextBox id='name' description='Component name' required />
                <TextBox id='modelCode' description='Model code' required />
                <TextBox id='description' description='Description' required />
                <TextBox id='manufacturer' description='Manufacturer' />
                <TextBox
                  id='functionalLocation'
                  description='Functional location'
                  required
                  readonly={true}
                  value={generateFunctionalLocation(values)}
                />
                <FormikSelect name='category' description='Category' options={componentCategories} />
                <FormikSelect name='templateId' description='Template' options={typedTemplates} />
                <TextBox id='quantity' description='Quantity' type='number' />
                <SaveAndCancel
                  dirty={dirty}
                  isValid={isValid}
                  cancel={props.setVisibility}
                  classes={'mt-2 flex gap-2'}
                />
              </Form>
            )
          }}
        </Formik>
      </div>
    </>
  )
}
