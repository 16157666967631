import { useState } from 'react'
import { useParams } from 'react-router-dom'

import Modal from '../../components/basic/DeleteResourceModal'
import { Table } from '../../components/basic/Table'
import { useOrganisation } from '../../hooks/useOrganisations'
import { useSites } from '../../hooks/useSites'
import { Header } from '../../layouts/Header'
import OrganisationsAPI from '../../services/organisations.service'
import { Tab } from '../../types/Tab'
import { AddSite } from './AddSite'

export default function Organisation() {
  const pageSize = 20
  const [page, setPage] = useState<number>(1)
  const [showAdd, setShowAdd] = useState<boolean>(false)
  const [showDelete, setShowDelete] = useState<boolean>(false)

  const { organisationId } = useParams()
  const organisation = useOrganisation(organisationId!)
  const sites = useSites(organisationId!, page, pageSize)

  const tabs: Tab[] = [
    {
      name: 'Add site',
      current: false,
      onClick: () => {
        setShowAdd(true)
      },
    },
    {
      name: 'Delete organisation',
      current: false,
      onClick: () => {
        setShowDelete(true)
      },
    },
  ]

  if (!sites) {
    return <div>No data</div>
  }

  const deleteText = 'Are you sure you want to delete this organisation?'

  return (
    <>
      <Header name={`Asset ${organisation?.organisation.name}`} tabs={tabs} />

      <div id='white-container' className='bg-white shadow rounded-lg p-4'>
        <div>
          {!showAdd && <Table collection='sites' rows={sites?.sites} />}

          {showAdd && (
            <AddSite
              onSaveSuccess={() => {
                setShowAdd(false)
              }}
              onCancel={() => {
                setShowAdd(false)
              }}
            />
          )}

          {showDelete && organisationId && (
            <Modal
              open={showDelete}
              setOpen={setShowDelete}
              label={'Delete organisation'}
              text={deleteText}
              service={OrganisationsAPI}
              resourceUrl={'organisations/'}
              resourceId={organisationId}
            />
          )}
        </div>
      </div>
    </>
  )
}
