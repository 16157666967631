import { debounce } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'

import { Item } from '../../types/Item'
import { IValue } from '../../types/IValues'

interface IProps {
  id: string
  name: string
  options: IValue[]
  selectedOptions: string[]
  setAvailableOptions: (options: IValue[]) => void
  setSelectedOptions: (options: string[]) => void
  lookUp: (searchValue: string) => Promise<IValue[]>
}

export default function SearchSelectBox(props: IProps) {
  const { id, name, options, selectedOptions, setSelectedOptions, setAvailableOptions, lookUp } = props
  const [searchValue, setSearchValue] = useState<string>('')
  const [showSearchResults, setShowSearchResults] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  async function search(e: any) {
    setSearchValue(e)
    setIsLoading(true)
    const results = await lookUp(e)
    setIsLoading(false)
    setAvailableOptions(results)
  }

  function selectAnOption(clickedRowEvent: any) {
    const target = clickedRowEvent.target.value
    const optionAlreadySelected = selectedOptions.some((x) => x === target)

    let newSelectedValues = selectedOptions.filter((x) => x !== target)

    if (optionAlreadySelected) {
      newSelectedValues = selectedOptions.filter((x) => x !== target)
      setSelectedOptions(newSelectedValues)
    } else {
      newSelectedValues.push(target)
      setSelectedOptions(newSelectedValues)
    }
  }

  const componentRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (componentRef.current && !componentRef.current.contains(event.target)) {
        setShowSearchResults(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [componentRef])

  function isChecked(value: string) {
    return selectedOptions.some((x) => x === value)
  }

  return (
    <div className='box flex-grow' ref={componentRef}>
      <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start pt-2 pl-4 pb-2 pr-2 '>
        <label htmlFor={`${id}-selectbox`} className='block text-sm text-gray-700 sm:mt-px sm:pt-2'>
          {name}
        </label>
        <div className='sm:col-span-2 '>
          <input
            id={`${id}-selectbox`}
            name={name}
            onChange={(e) => search(e.target.value)}
            onFocus={() => setShowSearchResults(true)}
            type='text'
            className='max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md'
            value={searchValue}
            role='searchbox'
          />
          <div className='absolute bg-white shadow'>
            {!isLoading && <div className='hidden'>{name} Loaded</div>}
            <ul>
              {options &&
                showSearchResults &&
                options.map((x) => (
                  <li key={x.label} className='m-2'>
                    <input
                      id={x.value}
                      value={x.value}
                      type='checkbox'
                      onClick={(e) => selectAnOption(e)}
                      defaultChecked={isChecked(x.value)}
                    />{' '}
                    <label htmlFor={x.value}>{x.label}</label>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
