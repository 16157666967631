import classNames from 'classnames'
import { Link, LinkProps, useMatch, useResolvedPath } from 'react-router-dom'

export function NavLink({ children, to, ...props }: LinkProps) {
  const resolved = useResolvedPath(to)
  const match = useMatch({ path: resolved.pathname, end: true })

  return (
    <Link
      className={classNames(
        'group flex items-center px-2 py-2  rounded-md',
        match ? 'bg-indigo-200 text-white' : 'hover:text-indigo-100 hover:bg-indigo-300 ',
      )}
      to={to}
      {...props}
    >
      {children}
    </Link>
  )
}
