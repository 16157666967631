import { Transition } from '@headlessui/react'
import { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript'

import TextBox from '../../components/basic/TextBoxCompressed'
import assetTemplates from '../../services/asset-templates.json'
import AssetsAPI from '../../services/assets.service'
import componentTemplates from '../../services/component-templates.json'
import { formatFieldName } from '../../utils/FormatFieldName'
import { ButtonGroupWithNotApplicable } from '../basic/ButtonGroupWithNotApplicable'

interface IProps {
  currentTemplateId: string
  organisationId: string
  assetId: string
  componentId: string
  formData: any
  isLocked: boolean
}

interface FieldToUpdate {
  templateId: string
  fieldName: string
  fieldValue: string
  componentId?: string
}

export function FormBuilder(props: IProps) {
  const { currentTemplateId, organisationId, assetId, componentId, formData, isLocked } = props
  const [isShown, setIsShown] = useState(true)

  const queryClient = useQueryClient()

  const templates = componentTemplates.concat(assetTemplates)

  const template = templates.find((x) => x.id === currentTemplateId)

  const updateCheckField = useMutation((fieldToUpdate: FieldToUpdate) => {
    const { templateId, fieldName, fieldValue, componentId } = fieldToUpdate
    return AssetsAPI.updateCheckField(organisationId, assetId, templateId, fieldName, fieldValue, componentId)
  })

  const handleSubmit = (templateId: string, fieldName: string, fieldValue: any): void => {
    const values: FieldToUpdate = {
      templateId,
      fieldName,
      fieldValue,
      componentId,
    }

    updateCheckField.mutate(values, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['assets', 'latestMaintenanceCheck', assetId])
      },
    })
  }

  function getValue(fieldName: string) {
    return formData && formData[fieldName] ? formData[fieldName] : ''
  }

  function HeadingOfCheck() {
    return (
      <div onClick={() => setIsShown((prevIsShown) => !prevIsShown)}>
        <div className='pl-2 text-xl bg-slate-100 rounded-md border flex'>
          <h1 className=''>{formatFieldName(template?.name)}</h1>
          <div className='pt-1 mb-1'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-6 w-6 '
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
              strokeWidth={2}
            >
              <path strokeLinecap='round' strokeLinejoin='round' d={isShown ? 'M19 9l-7 7-7-7' : 'M9 5l7 7-7 7'} />
            </svg>
          </div>
        </div>
      </div>
    )
  }

  console.log(formData)

  return (
    <div className='space-y-8 divide-y divide-gray-200'>
      <div aria-label={formatFieldName(template?.name)}>
        <HeadingOfCheck />

        <Transition show={isShown}>
          <div className='grid grid-cols-1 gap-6 sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-6'>
            {template &&
              template.fields.map((field) => {
                if (field.type === 'string' || field.type === 'number') {
                  return (
                    <TextBox
                      key={field.name}
                      id={field.name}
                      name={formatFieldName(field.name)}
                      type={field.type}
                      handleChange={(e) => handleSubmit(template.id, field.name, e.target.value)}
                      value={getValue(field.name)}
                      isReadOnly={isLocked}
                      disabled={true}
                    />
                  )
                }

                if (field.type === ('conditionWithNotApplicable' || 'condition' || 'installation')) {
                  return (
                    <div key={field.name}>
                      <div className='space-y-6 sm:space-y-5'>
                        <div className='items-center pt-2'>
                          <label htmlFor={field.name} className='col-span-1 block text-sm text-gray-700 sm:mt-px '>
                            {formatFieldName(field.name)}
                          </label>
                          <ButtonGroupWithNotApplicable
                            handleChange={(e) => handleSubmit(template.id, field.name, e.currentTarget.value)}
                            value={getValue(field.name)}
                            isReadOnly={isLocked}
                            disabled={true}
                          />
                        </div>
                      </div>
                    </div>
                  )
                }

                return null
              })}
          </div>
        </Transition>
      </div>
    </div>
  )
}
