interface IProps {
  id: string
  name: string
  onChange: (e: any) => void
  rows?: number
}

export function TextArea(props: IProps) {
  const { name, rows, onChange } = props

  return (
    <>
      <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:border-gray-200 '></div>
      <div>
        <label htmlFor='comment' className='block text-sm text-gray-700'>
          {name}
        </label>
        <div className='mt-1'>
          <textarea
            rows={rows ? rows : 4}
            name={name}
            id='comment'
            className='shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
            onChange={onChange}
          />
        </div>
      </div>
    </>
  )
}
