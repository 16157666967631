import assetTemplates from '../../../services/asset-templates.json'
import { Cell } from '../../../types/Cell'
import { Risk } from '../../../types/Risk'
import { formatFieldName } from '../../../utils/FormatFieldName'

export function UnSatisfactoryItemMapper(
  checks: any,
  risks: Risk[],
  assetId: string,
  createRiskForField: (templateId: string, fieldName: string) => void,
): Cell[][] {
  if (checks != null) {
    const checkListFields = checks.flatMap((c: any) => {
      const objectEntries = Object.entries(c)
      const fieldsAndValues = objectEntries
        .filter(([key, value]) => key !== 'templateId')
        .map(([key, value]) => {
          return { templateId: c.templateId, fieldName: key, fieldValue: value }
        })
      return fieldsAndValues
    })

    const failedItems = checkListFields.filter((x: any) => x.fieldValue === 'false')
    const failedItemsThatHaventAlreadyHadARiskRaised = failedItems.filter(
      (x: any) => hasOriginatingRisk(assetId, x.templateId, x.fieldName, risks) === false,
    )

    const unSatisfactoryItems = failedItemsThatHaventAlreadyHadARiskRaised.map((x: any) => [
      { value: getTemplateName(x.templateId), function: () => createRiskForField(x.templateId, x.fieldName) },
      { value: formatFieldName(x.fieldName), function: () => createRiskForField(x.templateId, x.fieldName) },
    ])

    return unSatisfactoryItems
  }
  return [] as Cell[][]
}

function hasOriginatingRisk(assetId: string, templateId: string, fieldName: string, risks: Risk[]): boolean {
  const risk = risks.find(
    (x: Risk) =>
      x.originatingAssetId === assetId &&
      x.originatingTemplateId === templateId &&
      x.originatingFieldName === fieldName,
  )

  return risk ? true : false
}

function getTemplateName(templateId: string): string {
  return assetTemplates.find((x) => x.id === templateId)?.name ?? 'Unknown'
}
