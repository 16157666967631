import BlueButton from './BlueButton'

interface IProps {
  cancel: (e: any) => void
  dirty: boolean
  isValid: boolean
  classes?: string
}

export function SaveAndCancel(props: IProps) {
  const { classes, cancel, dirty, isValid } = props
  return (
    <div className={'flex gap-2 ' + classes}>
      <BlueButton handleClick={cancel} type='button' text='Cancel' />
      <BlueButton type='submit' text='Save' disabled={!dirty || !isValid} />
    </div>
  )
}
