import {
  ChartBarIcon,
  CheckCircleIcon,
  ClipboardCheckIcon,
  CogIcon,
  FireIcon,
  HomeIcon,
  UsersIcon,
} from '@heroicons/react/outline'
import { useParams } from 'react-router'

import { useAsset } from '../hooks/useAssets'
import { useSite } from '../hooks/useSites'
import { NavLink } from './NavLink'

interface Props {
  mobileSideBarOpen: boolean
}

export function SideBar(props: Props) {
  const { mobileSideBarOpen } = props
  const { organisationId, siteId, assetId } = useParams()

  const site = useSite(siteId)
  const asset = useAsset(assetId)

  const navigation = [
    { name: 'Overview', href: '/', icon: HomeIcon },
    {
      name: 'Organisations',
      href: '/organisations',
      icon: UsersIcon,
    },
  ]

  if (organisationId === undefined && siteId === undefined && assetId === undefined) {
    navigation.push({
      name: 'Risk reporting',
      href: 'reporting',
      icon: ChartBarIcon,
    })
  }

  console.log(navigation)

  if (organisationId || siteId || assetId) {
    let orgId = organisationId
    if (organisationId === undefined && siteId) {
      orgId = site?.organisationId
    }
    if (organisationId === undefined && assetId) {
      orgId = asset?.organisationId
    }

    navigation.push({
      name: 'Risk reporting',
      href: `/organisations/${orgId}/reporting`,
      icon: ChartBarIcon,
    })
  }

  if (assetId) {
    navigation.push(
      { name: 'Asset', href: `/assets/${assetId}`, icon: CogIcon },
      {
        name: 'Risks',
        href: `/assets/${assetId}/risks`,
        icon: FireIcon,
      },
      { name: 'Current check', href: `/assets/${assetId}/currentcheck`, icon: ClipboardCheckIcon },
      {
        name: 'Previous checks',
        href: `/assets/${assetId}/checks`,
        icon: CheckCircleIcon,
      },
    )
  }

  function InnerSideBar() {
    return (
      <div className=' relative' style={{ minHeight: '12rem' }}>
        <div className='absolute inset-0 rounded-lg text-sm '>
          {navigation.map((item) => (
            <div key={item.name} className='m-1'>
              <NavLink to={item.href}>
                <item.icon style={{ strokeWidth: '1' }} className='mr-3 h-6 w-6  ' aria-hidden='true' />
                <div>{item.name}</div>
              </NavLink>
            </div>
          ))}
        </div>
      </div>
    )
  }

  return (
    <>
      <div id='sidebar' className='hidden w-60 shadow-inner xl:flex xl:flex-col xl:flex-shrink-0'>
        <InnerSideBar />
      </div>
      {mobileSideBarOpen && (
        <div id='mobileSideBar' className='sm:hidden w-60 shadow-inner flex flex-col flex-shrink-0 '>
          <InnerSideBar />
        </div>
      )}
    </>
  )
}
