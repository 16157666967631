import { Field } from 'formik'

interface IProps {
  id: string
  description: string
}

export function CheckBox(props: IProps) {
  const { id, description } = props
  return (
    <>
      <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-1 sm:pb-1'>
        <div>
          <label htmlFor={props.id} className='col-span-1 block text-sm text-gray-700 sm:mt-px'>
            {description}
          </label>
        </div>
        <div>
          <Field name={id} type='checkbox' />
        </div>
        <div></div>
      </div>
    </>
  )
}
