import { useQuery } from 'react-query'

import AreasAPI from '../services/areas.service'

export const useAreas = (areaId: string, page: number, pageSize: number) => {
  const { data: areaData } = useQuery(
    ['areas', areaId, { page, pageSize }],
    async () => await AreasAPI.getArea(areaId!),
    {
      enabled: !!areaId,
    },
  )

  return areaData
}

export const useAreasBySite = (siteId: string, page: number, pageSize: number) => {
  const { data: areas } = useQuery(
    ['areas', siteId, { page, pageSize }],
    async () => await AreasAPI.getAreas(siteId!, page, pageSize),
    {
      enabled: !!siteId,
    },
  )

  return areas
}
