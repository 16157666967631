import { useState } from 'react'

import { Table } from '../../components/basic/Table'
import { useOrganisations } from '../../hooks/useOrganisations'
import { Header } from '../../layouts/Header'
import { Tab } from '../../types/Tab'
import { AddOrganisation } from './AddOrganisation'

export default function Organisations() {
  const [page, setPage] = useState<number>(0)
  const [showAdd, setShowAdd] = useState<boolean>(false)
  const pageSize = 20

  const data = useOrganisations(page, pageSize)
  const rows = data?.organisations.map((x) => ({ id: x.id, name: x.name })) ?? []

  const organisationTabs: Tab[] = [
    {
      name: 'Add organisation',
      href: '#',
      current: false,
      onClick: () => {
        setShowAdd(true)
      },
    },
  ]

  return (
    <>
      <Header name='Organisations' tabs={organisationTabs} />
      <div id='white-container' className='bg-white shadow rounded-lg p-4'>
        {!showAdd && <Table collection='organisations' rows={rows} />}

        {showAdd && (
          <AddOrganisation
            onSaveSuccess={() => {
              setShowAdd(false)
            }}
            onCancel={() => {
              setShowAdd(false)
            }}
          />
        )}
      </div>
    </>
  )
}
