import { FileModel } from '../types/FileModel'
import { CreateRiskRequest, Risk } from '../types/Risk'
import HttpService from './http.service'

interface GetRisksSearchResponse {
  risks: Risk[]
  total: number
}

interface FilesResponse {
  files: FileModel[]
}

interface RiskSearchRequest {
  organisationId?: string
  siteIds?: string[]
  areaIds?: string[]
  assetIds?: string[]
  isResolved?: boolean
  page?: number
  pageSize?: number
}

class RisksAPI extends HttpService {
  getRisk = async (riskId: string): Promise<Risk> => {
    return await this.get(`risks/${riskId}`)
  }

  searchRisksByOrganisation = async (
    organisationId: string,
    siteIds: string[],
    areaIds: string[],
    assetIds: string[],
  ): Promise<GetRisksSearchResponse> => {
    const request: RiskSearchRequest = {
      organisationId,
      siteIds,
      areaIds,
      assetIds,
    }
    return await this.post('Risks/Searches', request)
  }

  getRisksByAssetId = async (assetId: string, isResolved?: boolean): Promise<GetRisksSearchResponse> => {
    const request: RiskSearchRequest = {
      assetIds: [assetId],
      isResolved: isResolved,
    }
    return await this.post('risks/searches', request)
  }

  searchRisks = async (
    organisationId?: string,
    siteIds?: string[],
    areaIds?: string[],
    assetIds?: string[],
    page?: number,
    pageSize?: number,
  ): Promise<GetRisksSearchResponse> => {
    const request: RiskSearchRequest = {
      organisationId,
    }
    return await this.post('Risks/Searches', request)
  }

  resolveRisks = async (ids: string[], resolution?: string) => {
    return await this.post('risks/commands/resolverisks', { ids, resolution })
  }

  postRisk = async (risk: CreateRiskRequest): Promise<Risk> => {
    return await this.post('risks', { ...risk })
  }

  putRisk = async (risk: Risk): Promise<Risk> => {
    return await this.put(`risks/${risk.id}/`, { ...risk })
  }

  getFiles = async (riskId: string): Promise<FilesResponse> => {
    return await this.get(`risks/${riskId}/files`)
  }

  postNote = async (riskId: string, description: string) => {
    return await this.post(`risks/${riskId}/notes`, { description: description })
  }

  deleteNote = async (riskId: string, noteId: string) => {
    return await this.delete(`risks/${riskId}/notes/${noteId}`)
  }

  postInternalNote = async (riskId: string, description: string) => {
    return await this.post(`risks/${riskId}/internalNotes`, { description: description })
  }

  deleteInternalNote = async (riskId: string, noteId: string) => {
    return await this.delete(`risks/${riskId}/internalNotes/${noteId}`)
  }
}

export default new RisksAPI({
  apiURL: process.env.REACT_APP_BACKENDURL,
})
