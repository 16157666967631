//
import { useContext } from 'react'
import { Link } from 'react-router-dom'

import { getTheme, ThemeContext } from '../../hooks/useTheme'
import { Cell } from '../../types/Cell'

interface IProps {
  headers: string[]
  rows: Cell[][]
  collection?: string
  queryParameter?: string
}

export function DynamicTable(props: IProps) {
  const theme = useContext(ThemeContext)

  const { collection, rows, headers } = props
  let isNavigatable = false
  if (collection != null) {
    isNavigatable = true
  }

  if (!rows) return <div className='text-base text-neutral-400 mb-6'>no data...</div>

  function TableHeader({ header }: { header: string }) {
    return (
      <th scope='col' className=' text-left text-sm text-white p-2 font-light'>
        {header}
      </th>
    )
  }

  function TableRow({ row, rowNumber }: { row: Cell[]; rowNumber: number }) {
    return (
      <>
        {row.map((cell, j) => (
          <td
            key={`${rowNumber}-${j}-cell`}
            className=' p-2 text-sm text-gray-800 hover:cursor-pointer'
            style={{ maxWidth: '120px', whiteSpace: 'normal' }}
          >
            {cell.link && <Link to={cell.link}>{cell.value}</Link>}
            {!cell.link && !cell.function && <div>{cell.value}</div>}
            {!cell.link && cell.function && <div onClick={cell.function}>{cell.value}</div>}
          </td>
        ))}
      </>
    )
  }

  return (
    <>
      {/* <div className=' shadow-sm ring-1 ring-black rounded-lg ring-opacity-5'> */}
      <div className='overflow-x-auto  whitespace-nowrap overflow-y-auto shadow-sm ring-1 ring-black rounded-lg ring-opacity-5'>
        <table className='w-full divide-y divide-gray-300'>
          <thead className='bg-blue-900 '>
            <tr className='divide-x divide-gray-400' style={{ height: 'max-content' }}>
              {headers.map((header, index) => (
                <TableHeader key={`${index}-header`} header={header} />
              ))}
            </tr>
          </thead>
          <tbody className='divide-y bg-slate-50 divide-gray-200'>
            {rows &&
              rows.map((row, i) => (
                <tr key={`${i}-row`} className={'divide-x'}>
                  <TableRow row={row} rowNumber={i} />
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  )
}
