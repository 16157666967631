import { useQuery } from 'react-query'

import SitesAPI from '../services/sites.service'

export const useSites = (organisationId: string, page: number, pageSize: number) => {
  const { data } = useQuery(
    ['sites', organisationId, { page, pageSize }],
    async () => await SitesAPI.getSites(page, pageSize, organisationId),
  )
  return data
}

export const useSite = (siteId?: string) => {
  const { data: siteData } = useQuery(['sites', siteId], async () => await SitesAPI.getSite(siteId!), {
    enabled: !!siteId,
  })

  return siteData
}
