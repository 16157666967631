import ForceGraph from 'force-graph'
import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { useWindowDimensions } from '../../hooks/useWindowDimensions'
import GraphAPI from '../../services/graphs.service'
import { getTheme } from '../../Theme'

interface Node {
  id: string
  fullId: string
  group: string
}

export default function GraphView() {
  const navigate = useNavigate()
  const theme = getTheme()
  const nodesResult = useQuery(['graphs', 'graphs'], () => GraphAPI.getNodes(), {
    enabled: true,
  })

  const { height, width } = useWindowDimensions()

  const nodes = nodesResult.data?.nodes ?? []
  const links = nodesResult.data?.links ?? []

  // function createRandomTree() {
  //   const N = 300
  //   const gData = {
  //     nodes: [...Array(N).keys()].map((i) => ({ id: i })),
  //     links: [...Array(N).keys()]
  //       .filter((id) => id)
  //       .map((id) => ({
  //         source: id,
  //         target: Math.round(Math.random() * (id - 1)),
  //       })),
  //   }

  //   return gData
  // }

  useEffect(() => {
    const gData = {
      nodes: nodes,
      links: links,
    }
    const Graph = ForceGraph()(document.getElementById('graph') as any)
      .linkDirectionalParticles(2)
      .nodeCanvasObject((node, ctx) => nodePaint(node, ctx))
      // .nodeAutoColorBy('group')
      // .nodeRelSize(6)
      .graphData(gData)
      .onNodeDragEnd((node) => {
        node.fx = node.x
        node.fy = node.y
      })
      .onNodeClick((node) => {
        navigateToNode(node as Node)
      })
      .width(1800)
      .height(900)
  }, [])

  function navigateToNode(node: Node) {
    navigate(`/${node.group}/${node.fullId}/`)
  }

  // const getColor = (n: any) => '#' + ((n * 1234567) % Math.pow(2, 24)).toString(16).padStart(6, '0')

  function nodePaint(node: any, ctx: any) {
    const { x, y, id, group } = node

    let colour = '#000'

    switch (group) {
      case 'organisations':
        colour = theme.A
        break
      case 'sites':
        colour = theme.B
        break
      case 'areas':
        colour = theme.C
        break
      case 'assets':
        colour = theme.D
        break
      case 'components':
        colour = theme.E
        break
      case 'hoses':
        colour = theme.F
        break
      case 'risks':
        colour = theme.Warning
        break
    }

    ctx.fillStyle = colour
    ctx.beginPath()

    let radius = 6
    if (group === 'organisations') {
      radius = 9
    }
    if (group === 'sites') {
      radius = 8
    }
    if (group === 'areas') {
      radius = 7
    }

    ctx.arc(x, y, radius, 0, 2 * Math.PI, false)
    ctx.fill()

    // () => {
    //   ctx.fillRect(x - 6, y - 4, 12, 8)
    // }, // rectangle
    // () => {
    //   ctx.beginPath()
    //   ctx.moveTo(x, y - 5)
    //   ctx.lineTo(x - 5, y + 5)
    //   ctx.lineTo(x + 5, y + 5)
    //   ctx.fill()
    // }, // triangle

    // circle
    // () => {
    //   ctx.font = '10px Sans-Serif'
    //   ctx.textAlign = 'center'
    //   ctx.textBaseline = 'middle'
    //   ctx.fillText('fgdfg', x, y)
    // }, // text
  }

  const pointEvents = ''
  // if (context.searchText) {
  //   pointEvents = 'pointer-events-none'
  // }

  return (
    <>
      <div className={`${pointEvents} min-h-full`} id='graph'></div>
    </>
  )
}
