import { ErrorMessage, Field } from 'formik'

interface IProps {
  id: string
  description: string
  required?: boolean
  rows?: number
  classes?: string
}

export function TextArea(props: IProps) {
  const { id, description, required, classes, rows } = props

  return (
    <div className={classes}>
      <label htmlFor={id} className='block text-gray-700 pb-2'>
        {description}
      </label>
      <Field
        id={id}
        name={id}
        required={required}
        as='textarea'
        rows={rows ? rows : 4}
        className='flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 rounded-md rounded-r-md sm:text-sm border-gray-300'
      />
    </div>
  )
}
