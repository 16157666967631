import { useState } from 'react'
import { useQuery } from 'react-query'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

import Modal from '../../components/basic/DeleteResourceModal'
import { SortableTable } from '../../components/basic/SortableTable'
import { useAreasBySite } from '../../hooks/useAreas'
import { useAssetsForSite } from '../../hooks/useAssets'
import { useSite } from '../../hooks/useSites'
import { Header } from '../../layouts/Header'
import AreasAPI from '../../services/areas.service'
import AssetsAPI from '../../services/assets.service'
import { Area } from '../../types/Area'
import { Asset } from '../../types/Asset'
import { Tab } from '../../types/Tab'
import { AddArea } from './AddArea'
import { AddAsset } from './AddAsset'

export default function Site() {
  const pageSize = 20
  const [page, setPage] = useState<number>(1)
  const [showArea, setShowArea] = useState<boolean>(false)
  const [showAsset, setShowAsset] = useState<boolean>(false)
  const [showDelete, setShowDelete] = useState<boolean>(false)

  const { siteId } = useParams()
  const queryClient = useQueryClient()

  const site = useSite(siteId)
  const areasData = useAreasBySite(siteId!, page, pageSize)
  const assetsData = useAssetsForSite(siteId!, page, pageSize)

  const tabs: Tab[] = [
    {
      name: 'Add asset',
      href: '#',
      current: false,
      onClick: () => {
        setShowArea(false)
        setShowAsset(true)
      },
    },
    {
      name: 'Add area',
      href: '#',
      current: false,
      onClick: () => {
        setShowArea(true)
        setShowAsset(false)
      },
    },
    {
      name: 'Delete site',
      current: false,
      onClick: () => {
        setShowDelete(true)
      },
    },
  ]

  const sortAssets = useMutation((assets: Asset[]) => {
    return AssetsAPI.sortAssets(assets)
  })

  const sortAreas = useMutation((areas: Area[]) => {
    return AreasAPI.sortAreas(areas)
  })

  const handleAreaSort = (areas: Area[]): void => {
    const isEqual =
      areas.length === areasData?.areas.length &&
      areas.every(function (value: any, index: any) {
        return value === areasData?.areas[index]
      })

    if (!isEqual) {
      sortAreas.mutate(areas, {
        onSuccess: async () => {
          await queryClient.invalidateQueries(['areas', siteId, { page, pageSize }])
        },
      })
    }
  }

  const handleAssetSort = (assets: Asset[]): void => {
    const isEqual =
      assets.length === assetsData?.assets.length &&
      assets.every(function (value: any, index: any) {
        return value === assetsData?.assets[index]
      })

    if (!isEqual) {
      sortAssets.mutate(assets, {
        onSuccess: async () => {
          await queryClient.invalidateQueries(['assets', siteId, { page, pageSize: pageSize }])
        },
      })
    }
  }

  const deleteText = 'Are you sure you want to delete this site?'

  return (
    <>
      <Header name={site?.name ?? ''} tabs={tabs} />

      <div id='white-container' className='bg-white shadow rounded-lg p-4'>
        {showArea && siteId && (
          <AddArea
            siteId={siteId}
            onSaveSuccess={() => {
              setShowArea(false)
            }}
            onCancel={() => {
              setShowArea(false)
            }}
          />
        )}

        {showAsset && siteId && (
          <AddAsset
            siteId={siteId}
            onSaveSuccess={() => {
              setShowAsset(false)
            }}
            onCancel={() => {
              setShowAsset(false)
            }}
          />
        )}

        {!showAsset && !showArea && (
          <div className='flex flex-grow'>
            <div className='w-3/12 mr-4'>
              <h1 className=' text-gray-900'>Areas</h1>
              <SortableTable collection='areas' rows={areasData?.areas ?? []} onSorted={handleAreaSort} />
            </div>

            <div className='w-3/12'>
              <h1 className='text-gray-900'>Assets</h1>
              <SortableTable collection='assets' rows={assetsData?.assets ?? []} onSorted={handleAssetSort} />
            </div>
          </div>
        )}

        {showDelete && siteId && (
          <Modal
            open={showDelete}
            setOpen={setShowDelete}
            label={'Delete site'}
            text={deleteText}
            resourceUrl={'sites/'}
            resourceId={siteId}
          />
        )}
      </div>
    </>
  )
}
