import BlueButton from '../components/basic/BlueButton'
import { Tab } from '../types/Tab'

interface Props {
  tabs: Tab[]
  classes?: string
}

export function ActionBar(props: Props) {
  const { tabs, classes } = props

  return (
    <div className={'flex gap-2 ' + classes}>
      {tabs.map((item) => (
        <BlueButton key={item.name} handleClick={item.onClick} text={item.name} />
      ))}
    </div>
  )
}
