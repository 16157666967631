import { Site } from '../types/Site'
import HttpService from './http.service'

class SitesAPI extends HttpService {
  getSite = async (siteId: string): Promise<Site> => {
    return await this.get(`sites/${siteId}`)
  }

  getSites = async (page: number, pageSize: number, organisationId?: string) => {
    if (organisationId) {
      return await this.get(`sites?organisationId=${organisationId}&page=${page}&pageSize=${pageSize}`)
    }

    return await this.get(`sites?page=${page}&pageSize=${pageSize}`)
  }

  postSite = async (id: string, name: string) => {
    const organisationId = id
    return await this.post('sites', { organisationId, name })
  }
}

export default new SitesAPI({
  apiURL: process.env.REACT_APP_BACKENDURL,
})
