import { Form, Formik } from 'formik'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import { SaveAndCancel } from '../../../components/basic/SaveAndCancel'
import { useHose } from '../../../hooks/useHoses'
import { Header } from '../../../layouts/Header'
import HosesAPI from '../../../services/hoses.service'
import { Hose } from '../../../types/Hose'
import { HoseForm } from './HoseForm'
import { HoseSchema } from './ValidateHose'

export function HosePage() {
  const { hoseId } = useParams()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const hoseData = useHose(hoseId!)

  const updateAsset = useMutation((hose: Hose) => {
    return HosesAPI.putHose(hose)
  })

  const handleSubmit = (hose: Hose): void => {
    updateAsset.mutate(hose, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['hoses', hoseId])
        navigate(-1)
      },
    })
  }

  const hoseName = 'Hose ' + hoseData?.hoseId?.toString() ?? ''

  return (
    <>
      <Header name={hoseName} tabs={[]} />
      <div id='white-container' className='bg-white shadow rounded-lg p-4'>
        <div className='pt-2'>
          {hoseData && (
            <Formik initialValues={hoseData} validationSchema={HoseSchema} onSubmit={handleSubmit}>
              {({ dirty, isValid, values }) => {
                return (
                  <Form>
                    <HoseForm />
                    <SaveAndCancel dirty={dirty} isValid={isValid} cancel={() => navigate(-1)} />
                  </Form>
                )
              }}
            </Formik>
          )}
        </div>
      </div>
    </>
  )
}
