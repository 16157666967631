import { BrowserRouter, Route, Routes } from 'react-router-dom'

import App from './App'
import Area from './pages/Area/Area'
import Asset from './pages/Assets/Asset'
import { ComponentPage } from './pages/Assets/Component/EditComponent'
import { CurrentCheck } from './pages/Assets/CurrentCheck'
import { HosePage } from './pages/Assets/Hose/EditHose'
import { PreviousChecks } from './pages/Assets/PreviousChecks'
import { EditRisk } from './pages/Assets/Risk/EditRisk'
import { Risks } from './pages/Assets/Risk/Risks'
import GraphView from './pages/Graph/GraphView'
import Organisation from './pages/Organisation/Organisation'
import Organisations from './pages/Organisation/Organisations'
import { Reporting } from './pages/Reporting/Reporting'
import SignIn from './pages/SignIn/SignIn'
import SignUp from './pages/SignUp/SignUp'
import Site from './pages/Site/Site'

export default function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<App />}>
          <Route index element={<GraphView />} />
          <Route path='organisations' element={<Organisations />} />
          <Route path='organisations/:organisationId' element={<Organisation />} />
          <Route path='sites' element={<Organisation />} />
          <Route path='sites/:siteId' element={<Site />} />
          <Route path='areas/:areaId' element={<Area />} />
          <Route path='assets/:assetId/risks' element={<Risks />} />
          <Route path='assets/:assetId/risks/:riskId' element={<EditRisk />} />
          <Route path='assets/:assetId/currentcheck' element={<CurrentCheck />} />
          <Route path='assets/:assetId/checks' element={<PreviousChecks />} />
          <Route path='assets/:assetId' element={<Asset />} />
          <Route path='components/:componentId' element={<ComponentPage />} />
          <Route path='hoses/:hoseId' element={<HosePage />} />
          <Route path='risks/:riskId' element={<EditRisk />} />
          <Route path='reporting' element={<Reporting />} />
          <Route path='organisations/:organisationId/reporting' element={<Reporting />} />
        </Route>
        <Route path='sign-in' element={<SignIn />} />
        <Route path='sign-up' element={<SignUp />} />
      </Routes>
    </BrowserRouter>
  )
}
