import { Organisation } from '../types/Organisation'
import HttpService from './http.service'

interface OrganisatonResponse {
  organisation: Organisation
}

interface OrganisationsResponse {
  organisations: Organisation[]
}

class OrganisationsAPI extends HttpService {
  getOrganisation = async (organisationId: string): Promise<OrganisatonResponse> => {
    return await this.get(`organisations/${organisationId}`)
  }

  getOrganisations = async (page: number, pageSize: number): Promise<OrganisationsResponse> => {
    return await this.get(`organisations?page=${page}&pageSize=${pageSize}`)
  }

  postOrganisation = async (name: string) => {
    return await this.post('organisations', { name })
  }
}

export default new OrganisationsAPI({
  apiURL: process.env.REACT_APP_BACKENDURL,
})
