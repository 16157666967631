import { Form, Formik } from 'formik'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { number, object, string } from 'yup'

import { SaveAndCancel } from '../../components/basic/SaveAndCancel'
import { FormikSelect } from '../../components/formik/FormikSelect'
import { TextBox } from '../../components/formik/TextBox'
import SitesAPI from '../../services/sites.service'
import { IValue } from '../../types/IValues'

interface IProps {
  onSaveSuccess: () => void
  onCancel: () => void
}

interface SiteForm {
  siteName: string
}

const siteSchema = object({
  siteName: string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
})

export function AddSite(props: IProps) {
  const queryClient = useQueryClient()
  const { onSaveSuccess, onCancel } = props
  const initialValues: SiteForm = { siteName: '' }

  const { organisationId } = useParams()

  const createSite = useMutation((values: SiteForm) => {
    return SitesAPI.postSite(organisationId!, values.siteName)
  })

  const handleSubmit = (values: SiteForm): void => {
    createSite.mutate(values, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['sites'])
        onSaveSuccess()
      },
    })
  }

  const siteOptions: IValue[] = [
    { value: 'Site', label: 'Site' },
    {
      value: 'Vessel',
      label: 'Vessel',
    },
    { value: 'PlantArea', label: 'Plant area' },
  ]

  return (
    <div className='space-y-8 divide-y divide-gray-200 sm:space-y-5'>
      <div className='pt-8 space-y-6 sm:pt-10 sm:space-y-5'>
        <h3 className='text-lg leading-6 text-gray-900'>Add a site</h3>
        <Formik initialValues={initialValues} validationSchema={siteSchema} onSubmit={handleSubmit}>
          {({ dirty, isValid }) => {
            return (
              <Form>
                <TextBox id='siteName' description='Site name' required />
                <FormikSelect name='siteType' description='Site type' options={siteOptions} />
                <SaveAndCancel dirty={dirty} isValid={isValid} cancel={onCancel} />
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}
