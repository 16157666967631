import { useQuery } from 'react-query'

import ComponentsAPI from '../services/components.service'

export const useComponents = (assetId: string, page: number, limit: number) => {
  const { data: componentsData } = useQuery(
    ['components', assetId],
    async () => await ComponentsAPI.getComponents(assetId!, page, limit),
    {
      enabled: !!assetId,
    },
  )

  return componentsData
}

export const useComponent = (componentId: string) => {
  const { data: componentData } = useQuery(
    ['components', componentId],
    async () => await ComponentsAPI.getComponent(componentId!),
    {
      enabled: !!componentId,
    },
  )

  return componentData
}
