import { Form, Formik } from 'formik'
import { useState } from 'react'
import { useRef } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import * as yup from 'yup'

import { DynamicTable } from '../../components/basic/DynamicTable'
import { Notes } from '../../components/basic/Notes'
import { SaveAndCancel } from '../../components/basic/SaveAndCancel'
import { TextArea } from '../../components/formik/TextArea'
import { TextBox } from '../../components/formik/TextBox'
import { useAsset } from '../../hooks/useAssets'
import { useComponents } from '../../hooks/useComponents'
import { useHoses } from '../../hooks/useHoses'
import { Header } from '../../layouts/Header'
import AssetsAPI from '../../services/assets.service'
import { Cell } from '../../types/Cell'
import { Tab } from '../../types/Tab'
import { AddComponent } from './Component/AddComponent'
import { AddHose } from './Hose/AddHose'

interface AssetForm {
  name: string
  description: string
}

export default function Asset() {
  const pageSize = 20
  const [page, setPage] = useState<number>(1)
  const { assetId } = useParams()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const [showAddComponent, setShowAddComponent] = useState<boolean>(false)
  const [showAddHose, setShowAddHose] = useState<boolean>(false)
  const [note, setNote] = useState<string>('')

  const tabs: Tab[] = [
    {
      name: 'Add hose',
      current: false,
      onClick: () => {
        setShowAddHose(true)
        setShowAddComponent(false)
      },
    },
    {
      name: 'Add component',
      current: false,
      onClick: () => {
        setShowAddHose(false)
        setShowAddComponent(true)
      },
    },
    {
      name: 'Export',
      current: false,
      onClick: () => {
        handlePrint()
      },
    },
  ]

  const assetSchema = yup.object({
    name: yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
  })

  const componentRef = useRef()

  const handlePrint = useReactToPrint({
    content: () => componentRef.current!,
  })

  const assetData = useAsset(assetId!)

  const componentsData = useComponents(assetId!, page, pageSize)
  const hosesData = useHoses(assetId!, page, pageSize)

  const updateAsset = useMutation((values: AssetForm) => {
    return AssetsAPI.putAsset(assetId!, values.name, values.description)
  })

  const handleSubmit = (values: AssetForm): void => {
    updateAsset.mutate(values, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['assets', assetId])
      },
    })
  }

  const headers = ['Name', 'Model code', 'Manufacturer', 'Description', 'Functional location', 'Category', 'Quantity']
  const components: Cell[][] =
    componentsData?.components?.map((component) => {
      const link = `/components/${component.id}`
      return [
        { value: component.name, link: link },
        { value: component.modelCode, link: link },
        { value: component.manufacturer, link: link },
        { value: component.description, link: link },
        { value: component.functionalLocation, link: link },
        { value: component.category, link: link },
        { value: component.quantity?.toString() ?? '1', link: link },
      ]
    }) ?? []

  const hoseHeaders = ['Id', 'End1', 'Material', 'End2', 'Condition score', 'Orientation']

  const hoses: Cell[][] =
    hosesData?.hoses?.map((hose) => {
      const link = `/hoses/${hose.id}`
      return [
        { value: hose.hoseId?.toString() ?? '', link: link },
        { value: hose.end1PartNumber ?? '', link: link },
        { value: hose.materialPartNumber ?? '', link },
        { value: hose.end2PartNumber ?? '', link: link },
        { value: hose.conditionScore?.toString() ?? '', link: link },
        { value: hose.orientation?.toString() ?? '', link: link },
      ]
    }) ?? []

  const addNoteMutation = useMutation((note: string) => {
    return AssetsAPI.postNote(assetId!, note)
  })

  function handleAddNote() {
    addNoteMutation.mutate(note, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['assets', assetId])
        setNote('')
      },
    })
  }

  const removeNoteMutation = useMutation((noteId: string) => {
    return AssetsAPI.deleteNote(assetId!, noteId)
  })

  function handleRemoveNote(noteId: string) {
    removeNoteMutation.mutate(noteId, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['assets', assetId])
      },
    })
  }

  const orderedNotes = assetData?.notes?.map((x) => x).reverse()

  if (assetData != null) {
    assetData.description = ''
  }

  // function AssetProperties() {
  //   return (
  //     <>
  //       {assetData && (
  //         <div className='w-2/5 pl-6 pt-12 '>
  //           <div ref={componentRef as any}>
  //             <div className='mb-2'>
  //               <Formik initialValues={assetData as AssetForm} validationSchema={assetSchema} onSubmit={handleSubmit}>
  //                 {({ dirty, isValid, values }) => {
  //                   return (
  //                     <>
  //                       <Form>
  //                         <TextBox id='name' description='Name' required />
  //                         <TextArea id='description' description='Description' rows={6} />
  //                         <SaveAndCancel
  //                           dirty={dirty}
  //                           isValid={isValid}
  //                           cancel={() => {
  //                             navigate(-1)
  //                           }}
  //                         />
  //                       </Form>
  //                     </>
  //                   )
  //                 }}
  //               </Formik>
  //             </div>

  //             <Notes
  //               id='notes'
  //               name='Notes'
  //               notes={orderedNotes}
  //               add={handleAddNote}
  //               remove={handleRemoveNote}
  //               note={note}
  //               setNote={setNote}
  //             />

  //             <div>Files</div>
  //             {/* <FileUpload organisationId={asset} /> */}
  //             <div className='max-w-7xl mx-auto mb-6 flex justify-between'></div>
  //           </div>
  //         </div>
  //       )}
  //     </>
  //   )
  // }

  return (
    <>
      <Header name={`${assetData?.name}`} tabs={tabs} />
      <div id='white-container' className='bg-white shadow rounded-lg p-4'>
        {!showAddComponent && !showAddHose && assetData && (
          <>
            <div className='flex flex-wrap justify-center gap-4'>
              <div className='shrink basis-2/4 grow max-w-2xl flex flex-col gap-2'>
                <p className='text-gray-700'>Parts list</p>
                <DynamicTable rows={components ?? []} headers={headers} collection='components'></DynamicTable>
                <p className='text-gray-700'>Hoses</p>
                <DynamicTable rows={hoses ?? []} headers={hoseHeaders} collection='hoses'></DynamicTable>
              </div>
              {/* </div> */}
              {/* <AssetProperties /> */}
              <div className='flex flex-col gap-2 grow basis-1/4' ref={componentRef as any}>
                <Formik initialValues={assetData as AssetForm} validationSchema={assetSchema} onSubmit={handleSubmit}>
                  {({ dirty, isValid, values }) => {
                    return (
                      <Form>
                        <TextBox id='name' description='Name' required />
                        <TextArea id='description' description='Description' rows={6} classes='mt-2' />
                        <SaveAndCancel
                          dirty={dirty}
                          isValid={isValid}
                          cancel={() => {
                            navigate(-1)
                          }}
                          classes={'mt-2 flex gap-2 justify-end'}
                        />
                      </Form>
                    )
                  }}
                </Formik>

                <Notes
                  id='notes'
                  name='Notes'
                  notes={orderedNotes}
                  add={handleAddNote}
                  remove={handleRemoveNote}
                  note={note}
                  setNote={setNote}
                />
                <div>Files</div>
                {/* <FileUpload organisationId={asset} /> */}
                <div className='max-w-7xl flex justify-between'></div>
              </div>
            </div>
          </>
        )}

        {showAddComponent && (
          <AddComponent
            setVisibility={() => {
              setShowAddComponent(false)
            }}
          />
        )}
        {showAddHose && (
          <AddHose
            setVisibility={() => {
              setShowAddHose(false)
            }}
          />
        )}
      </div>
    </>
  )
}
