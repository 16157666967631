import { format, formatDistance, formatRelative, parseISO, subDays } from 'date-fns'
import { useParams } from 'react-router'

import { Table } from '../../components/basic/Table'
import { usePreviousMaintenanceChecks } from '../../hooks/useAssets'
import { Header } from '../../layouts/Header'
import { MaintenanceCheck } from '../../types/MaintenanceCheck'
import { Row } from '../../types/Row'

export function PreviousChecks() {
  const { assetId } = useParams()

  const previousChecks = usePreviousMaintenanceChecks(assetId!)

  console.log(previousChecks)

  function getDate(date: string) {
    const dateParsed = parseISO(date)
    return format(dateParsed, 'yyyy-MM-dd HH:mm:ss')
  }

  const previousMaintenanceCheckRows: Row[] =
    previousChecks?.data?.checks.map((x: MaintenanceCheck) => ({
      id: x.id,
      name: getDate(x.lastUpdated),
    })) ?? []

  return (
    <>
      <Header name={'Previous checks'} tabs={[]} />
      <Table rows={previousMaintenanceCheckRows} />
    </>
  )
}
