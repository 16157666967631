import { useState } from 'react'
import { useParams } from 'react-router-dom'

import SearchSelectBox from '../../components/advanced/SearchSelectBox'
import { DynamicTable } from '../../components/basic/DynamicTable'
import { useOrganisations } from '../../hooks/useOrganisations'
import { useRiskSearch } from '../../hooks/useRisks'
import { useAreaSearches, useAssetSearches, useSiteSearches } from '../../hooks/useSearches'
import { Header } from '../../layouts/Header'
import SearchesAPI from '../../services/searches.service'
import { IValue } from '../../types/IValues'
import { Tab } from '../../types/Tab'

export function Reporting() {
  let { organisationId } = useParams()
  const [selectedSites, setSelectedSites] = useState<string[]>([])
  const [selectedAreas, setSelectedAreas] = useState<string[]>([])
  const [selectedAssets, setSelectedAssets] = useState<string[]>([])
  const [displayedSiteOptions, setDisplayedSiteOptions] = useState<IValue[]>()
  const [displayedAreaOptions, setDisplayedAreaOptions] = useState<IValue[]>()
  const [displayedAssetOptions, setDisplayedAssetOptions] = useState<IValue[]>()

  const organisations = useOrganisations(1, 40)

  if (organisationId == null) {
    organisationId = organisations?.organisations[0].id
  }

  const orgId = organisationId!

  const siteOptions = useSiteSearches(orgId)
  const areaOptions = useAreaSearches(orgId, selectedSites)
  const assetOptions = useAssetSearches(orgId, selectedSites, selectedAreas)
  const risksData = useRiskSearch(orgId, selectedSites, selectedAreas, selectedAssets)

  function downloadCSV() {
    const headers = Object.keys(risksData?.risks[0] || {})
    const rows =
      risksData?.risks.map((risk) => {
        return Object.values(risk)
      }) || []

    const headersCSV = headers.join(',') + '\n'
    const csvContent = 'data:text/csv;charset=utf-8,' + headersCSV + rows.map((e) => e.join(',')).join('\n')
    const encodedUri = encodeURI(csvContent)
    window.open(encodedUri)
  }

  const tabs = [
    {
      name: 'Download',
      current: false,
      onClick: () => {
        downloadCSV()
      },
    },
  ] as Tab[]

  async function searchForSites(searchValue: string): Promise<IValue[]> {
    if (organisationId == null) throw new Error('Organisation ID is null')

    const result = await SearchesAPI.searchSites(organisationId, searchValue)
    const sites = result?.items.map((x) => ({ label: x.name, value: x.id }))
    return sites
  }

  async function searchForAreas(searchValue: string): Promise<IValue[]> {
    if (organisationId == null) throw new Error('Organisation ID is null')
    const result = await SearchesAPI.searchAreas(organisationId, selectedSites, searchValue)
    const areas = result?.items.map((x) => ({ label: x.name, value: x.id }))
    return areas
  }

  async function searchForAssets(searchValue: string): Promise<IValue[]> {
    if (organisationId == null) throw new Error('Organisation ID is null')
    const result = await SearchesAPI.searchAssets(organisationId, selectedSites, selectedAssets, searchValue)
    const assets = result?.items.map((x) => ({ label: x.name, value: x.id }))
    return assets
  }

  const sites = (siteOptions?.items.map((x) => ({ value: x.id, label: x.name })) as IValue[]) ?? []
  const areas = (areaOptions?.items.map((x) => ({ value: x.id, label: x.name })) as IValue[]) ?? []
  const assets = (assetOptions?.items.map((x) => ({ value: x.id, label: x.name })) as IValue[]) ?? []

  let headers: string[] = []
  if (risksData && risksData.risks.length > 0) {
    headers = Object.keys(risksData.risks[0])
  }

  if (risksData && risksData.risks.length > 0) {
    console.log('blah')
  }

  const SearchOptions = () => {
    return (
      <div>
        <div className='flex flex-wrap bg-white p-4 shadow'>
          {sites && (
            <SearchSelectBox
              id='sites'
              name='Sites'
              options={displayedSiteOptions ?? sites}
              selectedOptions={selectedSites}
              setAvailableOptions={setDisplayedSiteOptions}
              setSelectedOptions={setSelectedSites}
              lookUp={searchForSites}
            />
          )}
          {areas && (
            <SearchSelectBox
              id='areas'
              name='Areas'
              options={displayedAreaOptions ?? areas}
              selectedOptions={selectedAreas}
              setAvailableOptions={setDisplayedAreaOptions}
              setSelectedOptions={setSelectedAreas}
              lookUp={searchForAreas}
            />
          )}
          {sites && (
            <SearchSelectBox
              id='assets'
              name='Assets'
              options={displayedAssetOptions ?? assets}
              selectedOptions={selectedAssets}
              setAvailableOptions={setDisplayedAssetOptions}
              setSelectedOptions={setSelectedAssets}
              lookUp={searchForAssets}
            />
          )}
        </div>
      </div>
    )
  }

  return (
    <>
      <Header name='Risk Reporting' tabs={tabs} />
      <SearchOptions />
      <div className='relative overlow-hidden' style={{ maxWidth: '100%' }}>
        <div id='white-container' className='bg-white shadow rounded-lg p-4 mt-4 absolute w-full left-0 top-0'>
          <DynamicTable headers={headers} rows={[]} collection='' />
        </div>
      </div>
    </>
  )
}
