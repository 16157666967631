import { Form, Formik } from 'formik'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { number, object, string } from 'yup'

import { SaveAndCancel } from '../../components/basic/SaveAndCancel'
import { TextBox } from '../../components/formik/TextBox'
import AssetAPI from '../../services/assets.service'

interface IProps {
  onSaveSuccess: () => void
  onCancel: () => void
  siteId: string
}

interface AssetForm {
  assetName: string
}

const assetSchema = object({
  assetName: string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
})

export function AddAsset({ siteId, onSaveSuccess, onCancel }: IProps) {
  const { areaId } = useParams()
  const initialValues: AssetForm = { assetName: '' }
  const queryClient = useQueryClient()

  const createAsset = useMutation((values: AssetForm) => {
    return AssetAPI.postAsset(siteId, values.assetName, areaId)
  })

  const handleSubmit = (values: AssetForm): void => {
    createAsset.mutate(values, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['assets', siteId])
        onSaveSuccess()
      },
    })
  }

  return (
    <div className='space-y-8 divide-y divide-gray-200 sm:space-y-5'>
      <div className='pt-8 space-y-6 sm:pt-10 sm:space-y-5'>
        <h3 className='text-lg leading-6 text-gray-900'>Add an asset</h3>
        <div>
          <Formik initialValues={initialValues} validationSchema={assetSchema} onSubmit={handleSubmit}>
            {({ dirty, isValid }) => {
              return (
                <Form>
                  <TextBox id='assetName' description='Asset name' required />
                  <SaveAndCancel dirty={dirty} isValid={isValid} cancel={onCancel} />
                </Form>
              )
            }}
          </Formik>
        </div>
      </div>
    </div>
  )
}
