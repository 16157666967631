import { useState } from 'react'
import { useQuery } from 'react-query'
import { useMutation, useQueryClient } from 'react-query'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

import { DynamicTable } from '../../../components/basic/DynamicTable'
import { Table } from '../../../components/basic/Table'
import { ResolveRiskModal } from '../../../components/popups/ResolveRiskModal'
import { useAsset } from '../../../hooks/useAssets'
import { useRisks } from '../../../hooks/useRisks'
import { Header } from '../../../layouts/Header'
import assetTemplates from '../../../services/asset-templates.json'
import AssetsAPI from '../../../services/assets.service'
import RisksAPI from '../../../services/risks.service'
import { Cell } from '../../../types/Cell'
import { CreateRiskRequest, Risk } from '../../../types/Risk'
import { SelectableRow } from '../../../types/Row'
import { SelectBox } from '../../../types/SelectBox'
import { formatFieldName } from '../../../utils/FormatFieldName'
import { UnSatisfactoryItemMapper } from './UnSatistfactoryItemMapper'

export function Risks() {
  const [selectedRisks, setSelectedRisks] = useState<SelectBox[]>([])
  const [showResolveRiskModal, setShowResolveRiskModal] = useState<boolean>(false)
  const [showResolvedRisks, setShowResolvedRisks] = useState<boolean>(false)
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { assetId } = useParams()

  if (assetId == null) {
    throw new Error('Asset ID is null')
  }

  const risksData = useRisks(assetId, showResolvedRisks)
  const assetData = useAsset(assetId)

  const tabsToDisplay = []
  const anySelected = selectedRisks.some((x) => x.selected)
  if (!anySelected) {
    tabsToDisplay.push({
      name: 'Add risk',
      href: '#',
      current: false,
      onClick: async () => {
        createRiskAndNavigateToRisk(assetData!.organisationId!, assetId, assetData!.name!)
      },
    })
  } else {
    tabsToDisplay.push({
      name: 'Resolve risks',
      href: '#',
      current: false,
      onClick: () => {
        setShowResolveRiskModal(true)
      },
    })
  }

  if (!showResolvedRisks) {
    tabsToDisplay.push({
      name: 'Show resolved risks',
      href: '#',
      current: false,
      onClick: async () => {
        setShowResolvedRisks(true)
      },
    })
  }

  if (showResolvedRisks) {
    tabsToDisplay.push({
      name: 'Hide resolved risks',
      href: '#',
      current: false,
      onClick: async () => {
        setShowResolvedRisks(false)
      },
    })
  }

  const createRisk = useMutation((values: CreateRiskRequest) => {
    return RisksAPI.postRisk(values)
  })

  const handleSubmit = (createRiskRequest: CreateRiskRequest): void => {
    createRisk.mutate(createRiskRequest, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['risks', createRiskRequest.originatingAssetId])
        navigate(`/assets/${assetId}/risks/${createRiskRequest.id}`)
      },
    })
  }

  async function createRiskAndNavigateToRisk(organisationId: string, assetId: string, assetName: string) {
    const riskToCreate = {
      id: uuidv4(),
      assets: [{ id: assetId, name: assetName }],
      organisationId: organisationId,
      originatingAssetId: assetId,
    } as Risk

    handleSubmit(riskToCreate)
  }

  const { data: assetMaintenanceCheckResult } = useQuery(['assets', 'latestMaintenanceCheck', assetId], () =>
    AssetsAPI.getLatestMaintenanceCheck(assetId!),
  )

  function toggleActions(selectBoxes: SelectBox[]) {
    setSelectedRisks(selectBoxes)
  }

  function resolveRisks(selectedRisks: SelectBox[], resolution?: string) {
    const anySelected = selectedRisks.filter((x) => x.selected).map((x) => x.id)
    RisksAPI.resolveRisks(anySelected, resolution)
  }

  const riskRows: SelectableRow[] =
    risksData?.risks.map((x: Risk) => ({
      id: x.id,
      name: x.name,
      selected: false,
    })) ?? []

  let unSatisfactoryItems: Cell[][] = [[]]

  if (assetMaintenanceCheckResult != null) {
    const checks = assetMaintenanceCheckResult.checks

    const createRiskForField = async (templateId: string, fieldName: string): Promise<void> => {
      const riskName = `${formatFieldName(fieldName)}`
      const riskToCreate = {
        id: uuidv4(),
        name: riskName,
        assets: [{ id: assetId, name: assetData!.name! }],
        organisationId: assetData!.organisationId!,
        originatingAssetId: assetId,
        originatingFieldName: fieldName,
        originatingTemplateId: templateId,
      } as Risk

      handleSubmit(riskToCreate)
    }

    const risks = risksData?.risks ?? []
    unSatisfactoryItems = UnSatisfactoryItemMapper(checks, risks, assetId, createRiskForField)
  }

  function handleResolveRiskSubmit(resolution: string) {
    resolveRisks(selectedRisks, resolution)
    setShowResolveRiskModal(false)
  }

  function hideResolveRiskModal() {
    setShowResolveRiskModal(false)
  }

  return (
    <>
      <Header name='Risks' tabs={tabsToDisplay} />
      <div id='white-container' className='bg-white shadow rounded-lg p-4'>
        <div className='flex flex-wrap'>
          {risksData && riskRows && (
            <div className='mr-4 w-1/3'>
              <Table
                rows={riskRows}
                collection={`${'assets'}/${assetId}${'/risks'}`}
                onSelectedRowClick={toggleActions}
              />
            </div>
          )}

          {assetMaintenanceCheckResult && (
            <div>
              <DynamicTable headers={['Template name', 'Field name']} rows={unSatisfactoryItems} />
            </div>
          )}

          <ResolveRiskModal
            open={showResolveRiskModal}
            handleSubmit={handleResolveRiskSubmit}
            handleCancel={hideResolveRiskModal}
          />
        </div>
      </div>
    </>
  )
}
