import { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { useMutation, useQueryClient } from 'react-query'

import fileUploadsService from '../../services/fileUploads.service'
import { Loading } from './Loading'

interface IProps {
  organisationId: string
  riskId: string
}

export function FileUpload(props: IProps) {
  const queryClient = useQueryClient()

  const uploadFile = useMutation(
    ({ fileName, base64 }: { fileName: string; base64: string | ArrayBuffer | null }) =>
      fileUploadsService.postFile(props.organisationId, props.riskId, fileName, base64),
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries(['risks', 'files'])
      },
    },
  )

  const onDrop = useCallback(
    (files: File[]) => {
      files.forEach((file) => {
        const fr = new FileReader()
        fr.onload = () => {
          const fileName = file.name.replace(/\s/g, '')
          uploadFile.mutate({ fileName, base64: fr.result })
        }
        fr.readAsDataURL(file)
      })
    },
    [uploadFile],
  )

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/jpeg,image/png,image/gif',
    multiple: false,
    maxSize: 1024 * 1024 * 10, // 10 MB
  })

  return (
    <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5'>
      <label htmlFor='file-upload' className='block text-sm text-gray-700 sm:mt-px sm:pt-2'>
        File Upload
      </label>
      <div className='mt-1 sm:mt-0 sm:col-span-2'>
        <div
          {...getRootProps({
            className: 'max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md',
          })}
        >
          <div className='space-y-1 text-center'>
            <svg
              className='mx-auto h-12 w-12 text-gray-400'
              stroke='currentColor'
              fill='none'
              viewBox='0 0 48 48'
              aria-hidden='true'
            >
              <path
                d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
                strokeWidth={2}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
            <div className='flex text-sm text-gray-600'>
              <span className='relative cursor-pointer bg-white rounded-md text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500'>
                Upload a file
              </span>
              <input {...getInputProps({ id: 'file-upload', name: 'file-upload', className: 'sr-only' })} />
              <p className='pl-1'>or drag and drop</p>
            </div>
            <p className='text-xs text-gray-500'>PNG, JPG, GIF up to 10 MB</p>
          </div>
        </div>
      </div>
    </div>
  )
}
