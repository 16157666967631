import { ErrorMessage, useField } from 'formik'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'

import { Item } from '../../types/Item'
import { IValue } from '../../types/IValues'

interface IProps {
  name: string
  description: string
  options?: IValue[]
  multiSelect?: boolean
  value?: string[] | Item[]
  lookUpFunction?: (inputValue: string) => Promise<IValue[]>
}

export function FormikSelect(props: IProps) {
  const { name, description, options, multiSelect, value, lookUpFunction } = props
  const [field, meta, helpers] = useField(name) // can pass 'props' into useField also, if 'props' contains a name attribute
  const { setValue, setTouched, setError } = helpers

  const setFieldProps = (selectedOption: any) => {
    if (multiSelect) {
      const multipleValues = selectedOption.map((option: any) => {
        return option.value
      })
      setValue(multipleValues)
    } else {
      setValue(selectedOption.value)
    }

    setTouched(true)
    setError(undefined)
  }

  let values: any

  if (value && value.length > 0) {
    if (value[0] instanceof String) {
      values = value.map((x) => ({ value: x, label: x })) ?? []
    } else {
      const itemValues = value as Item[]
      values = itemValues.map((x) => ({ value: x.id, label: x.name })) ?? []
    }
  }

  return (
    <div className='space-y-6 sm:space-y-5'>
      <div className='items-center sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  pt-2 '>
        <label htmlFor={name} className='col-span-1 block text-sm text-gray-700 sm:mt-px '>
          {description}
        </label>

        {lookUpFunction ? (
          <AsyncSelect
            name={name}
            isMulti={multiSelect}
            loadOptions={lookUpFunction}
            cacheOptions={true}
            onChange={(selectedOption) => setFieldProps(selectedOption)}
            defaultValue={values}
            className='col-span-2'
          />
        ) : (
          <Select
            name={name}
            isMulti={multiSelect}
            options={options}
            onChange={(selectedOption) => setFieldProps(selectedOption)}
            defaultValue={values}
            className='col-span-2'
          />
        )}

        {/* <ErrorMessage name={name} /> */}
      </div>
    </div>
  )
}
