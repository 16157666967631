import { Area } from '../types/Area'
import HttpService from './http.service'

interface GetAreasResponse {
  areas: Area[]
}

class AreasAPI extends HttpService {
  getArea = async (areaId: string): Promise<Area> => {
    return await this.get(`areas/${areaId}`)
  }

  getAreas = async (siteId: string, page: number, pageSize: number): Promise<GetAreasResponse> => {
    return await this.get(`areas?siteId=${siteId}&page=${page}&pageSize=${pageSize}`)
  }

  postArea = async (siteId: string, name: string) => {
    return await this.post('areas', { siteId, name })
  }

  sortAreas = async (assets: Area[]) => {
    const items = assets.map((area, index) => ({ id: area.id, sequence: index }))
    return await this.post('areas/commands/sort', { items: items })
  }
}

export default new AreasAPI({
  apiURL: process.env.REACT_APP_BACKENDURL,
})
