interface IProps {
  handleClick?: (e: any) => void
  text?: string
  classes?: string
  styles?: object
  type?: 'submit' | 'reset' | 'button' | undefined
  disabled?: boolean
}

export default function BlueButton(props: IProps) {
  const { text, classes, styles, handleClick, type = 'button', disabled = false, ...other } = props
  return (
    <button
      {...other}
      className={
        'py-2 px-4 border border-transparent shadow-sm rounded-md text-white bg-blue-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ' +
        classes
      }
      onClick={handleClick}
      style={styles}
      type={type}
      disabled={disabled}
    >
      {text}
    </button>
  )
}
