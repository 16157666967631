import { Transition } from '@headlessui/react'
import { XCircleIcon } from '@heroicons/react/outline'
import { format, formatDistance, formatRelative, parseISO, subDays } from 'date-fns'
import { useState } from 'react'

import { Note } from '../../types/Note'
import BlueButton from './BlueButton'

interface IProps {
  id: string
  name: string
  notes?: Note[]
  add: () => void
  remove: (noteId: string) => void
  setNote: (value: string) => void
  note: string
}

export function Notes(props: IProps) {
  const { id, name, notes, add, remove, setNote, note } = props
  const [isShown, setIsShown] = useState(true)

  return (
    <>
      <div className='text-right'>
        <label
          htmlFor={name}
          className='block text-left text-gray-700'
          onClick={() => setIsShown((isShown) => !isShown)}
        >
          {name}
        </label>
        <Transition show={isShown}>
          <textarea
            id='notes'
            rows={4}
            name={name}
            className='shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md my-2'
            defaultValue={''}
            onChange={(e) => {
              setNote(e.target.value)
            }}
          />
          <BlueButton text='Add' handleClick={add} type='submit' />
        </Transition>
      </div>
      {notes?.map((note, i) => (
        <div key={`${i}-note-${name}`} className={''}>
          <div className='text-sm border rounded bg-gray-100 mt-1 pl-2 mb-1'>{note.description}</div>
          <div className='flex pl-2'>
            <div className='text-xs'>{note.userSummary.email}</div>
            <div className='text-xs'>
              {note.userSummary.firstName}
              {note.userSummary.lastName}
            </div>
            <div className='text-xs ml-2'>{formatRelative(parseISO(note.dateCreated), new Date())}</div>
            <XCircleIcon
              className='block h-4 w-4 text-gray-500 hover:text-red-500'
              aria-hidden='true'
              onClick={() => {
                remove(note.id)
              }}
            />
          </div>
        </div>
      ))}
    </>
  )
}
