import { useQuery } from 'react-query'

import RisksAPI from '../services/risks.service'

export const useRisks = (assetId: string, isResolved?: boolean) => {
  const { data: risksData } = useQuery(
    ['risks', assetId, isResolved],
    async () => await RisksAPI.getRisksByAssetId(assetId, isResolved),
  )
  return risksData
}

export const useRisksNotResolved = (assetId: string) => {
  const { data: risksData } = useQuery(['risks', assetId], async () => await RisksAPI.getRisksByAssetId(assetId))
  return risksData
}

export const useRisk = (riskId: string) => {
  const { data: riskData } = useQuery(['risks', riskId], async () => await RisksAPI.getRisk(riskId))
  return riskData
}

export const useRiskSearch = (
  organisationId: string,
  selectedSites: string[],
  selectedAreas: string[],
  selectedAssets: string[],
) => {
  const { data: risksData } = useQuery(
    ['risks', organisationId],
    async () => await RisksAPI.searchRisksByOrganisation(organisationId, selectedSites, selectedAreas, selectedAssets),
    {
      enabled: !!organisationId,
    },
  )
  return risksData
}
