import 'react-datepicker/dist/react-datepicker.css'

import { useField, useFormikContext } from 'formik'
import DatePicker from 'react-datepicker'

interface IProps {
  fieldName: string
}

export const FormikCalendar = ({ fieldName }: IProps) => {
  const [field, _meta, helpers] = useField(fieldName)
  const { setValue, setTouched, setError } = helpers
  return (
    <DatePicker
      dateFormat='dd/MM/yyyy'
      className='flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-md rounded-r-md sm:text-sm border-gray-300'
      selected={field.value ? new Date(field.value) : null}
      onChange={(val) => setValue(val)}
    />
  )
}
