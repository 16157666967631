import { Form, Formik } from 'formik'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { object, string } from 'yup'

import { SaveAndCancel } from '../../../components/basic/SaveAndCancel'
import { FormikSelect } from '../../../components/formik/FormikSelect'
import { TextBox } from '../../../components/formik/TextBox'
import { useComponent } from '../../../hooks/useComponents'
import { Header } from '../../../layouts/Header'
import templates from '../../../services/component-templates.json'
import ComponentsAPI from '../../../services/components.service'
import { Component } from '../../../types/Component'
import { IValue } from '../../../types/IValues'

export function ComponentPage() {
  const { componentId } = useParams()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const componentData = useComponent(componentId!)

  const updateAsset = useMutation((values: Component) => {
    return ComponentsAPI.putComponent(values)
  })

  const handleSubmit = (values: Component): void => {
    const component = { ...values, componentId }

    updateAsset.mutate(component, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['components', componentId])
        navigate(-1)
      },
    })
  }

  const componentSchema = object({
    name: string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
    modelCode: string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
  })

  const typedTemplates: IValue[] = templates.map((x) => {
    return { value: x.id, label: x.name }
  })

  const templateId = componentData?.templateId
  const templateName = templates.find((x) => x.id === templateId)?.name

  return (
    <>
      <Header name={'Edit component'} tabs={[]} />
      <div id='white-container' className='bg-white shadow rounded-lg p-4'>
        <div className='pt-2'>
          {componentData && (
            <Formik initialValues={componentData} validationSchema={componentSchema} onSubmit={handleSubmit}>
              {({ dirty, isValid, values }) => {
                return (
                  <Form>
                    <TextBox id='name' description='Component name' required />
                    <TextBox id='modelCode' description='Model code' required />
                    <TextBox id='description' description='Description' required />
                    <TextBox id='manufacturer' description='Manufacturer' />

                    <TextBox
                      id='functionalLocation'
                      description='Functional location'
                      required
                      readonly={true}
                      value={componentData.functionalLocation}
                    />

                    <TextBox
                      id='category'
                      description='Category'
                      required
                      readonly={true}
                      value={componentData.category}
                    />

                    <FormikSelect
                      name='templateId'
                      description='Template'
                      options={typedTemplates}
                      value={[{ id: templateId!, name: templateName! }]}
                    />
                    <SaveAndCancel
                      dirty={dirty}
                      isValid={isValid}
                      cancel={() => navigate(-1)}
                      classes={'mt-2 flex gap-2'}
                    />
                  </Form>
                )
              }}
            </Formik>
          )}
        </div>
      </div>
    </>
  )
}
