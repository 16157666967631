/* This example requires Tailwind CSS v2.0+ */
import { Transition } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/outline'
import { XIcon } from '@heroicons/react/solid'
import { Fragment } from 'react'

interface IProps {
  closePopUp: (e: any) => void
  isVisible: boolean
}

export function SuccessPopup(props: IProps) {
  const { closePopUp, isVisible } = props

  return (
    <Transition
      show={isVisible}
      as={Fragment}
      enter='transform ease-out duration-300 transition'
      enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
      enterTo='translate-y-0 opacity-100 sm:translate-x-0'
      leave='transition ease-in duration-100'
      leaveFrom='opacity-100'
      leaveTo='opacity-0'
    >
      <div
        aria-live='assertive'
        className='fixed right-4 top-4 z-50 max-w-sm w-full bg-white shadow-lg rounded-lg ring-1 ring-black ring-opacity-5 overflow-hidden border border-green-400'
      >
        <div className='p-4'>
          <div className='flex items-start'>
            <div className='flex-shrink-0'>
              <CheckCircleIcon className='h-6 w-6 text-green-400' aria-hidden='true' />
            </div>
            <div className='ml-3 w-0 flex-1 pt-0.5'>
              <p className='text-sm font-medium text-gray-900'>Successfully saved!</p>
              <p className='mt-1 text-sm text-gray-500'>Anyone with a link can now view this file.</p>
            </div>
            <div className='ml-4 flex-shrink-0 flex'>
              <button
                type='button'
                className='cursor-pointer	bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                onClick={closePopUp}
              >
                <span className='sr-only'>Close</span>
                <XIcon className='h-5 w-5' aria-hidden='true' />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  )
}
