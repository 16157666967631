import React, { createContext, useContext, useEffect, useState } from 'react'

import { Theme } from '../Theme'

const defaultTheme = {
  A: 'white', // Brown
  B: '#FFD426', // Yellow
  C: '#F5C60C', // Dark Yellow
  D: '#0008A8', // Blue
  E: '#8E93F5', // Purple
  F: '#5493F5', // Light Purple
  Warning: '#A80600',
}

const IMGTheme = {
  A: '#A88700', // Brown
  B: '#FFD426', // Yellow
  C: '#F5C60C', // Dark Yellow
  D: '#0008A8', // Blue
  E: '#8E93F5', // Purple
  F: '#5493F5', // Light Purple
  Warning: '#A80600',
}

const DarkTheme = {
  A: '#082032',
  B: '#2C394B',
  C: '#334756',
  D: '#FF4C29',
  E: '#8E93F5',
  F: '#5493F5',
  Warning: '#A80600',
}

// The theme
export const ThemeContext = createContext(defaultTheme as Theme)

export const getTheme = (themeName: string): Theme => {
  const theme = defaultTheme
  if (themeName !== 'default') {
    if (themeName === 'DarkTheme') {
      return DarkTheme
    }
    if (themeName === 'IMGTheme') {
      return IMGTheme
    }
  }

  return DarkTheme
}

// A hook
export function useTheme(): Theme {
  const theme = useContext(ThemeContext)
  return theme
}
